import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../../../assets/img/home2/about-cover.jpg";

const About = ({ data }) => {
  const {
    experience = 15,
    readMore = true,
    image=require(aboutImg),
    header = "About Company",
    subHeader = "We Always Think On Your Dream",
    quoteText = "Construction actually recommends for content specifically the people for the house keywords is popular.",
    detailText = "You should only pick the keywords that are both popular with people and ones that you’re also able to rank for. Brian Dean actually recommends creating content specifically for the people for the house.",
  } = data;

  return (
    <section className="about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-12 pe-xl-0">
            <div
              className="about-cover-bg bg-cover me-xl-5"
              style={{ backgroundImage: `url(${image})` }}
            >
              <div className="our-experience-years">
                <div className="year-outline">
                  <h2>{experience}</h2>
                </div>
                <p>
                  Years <span>Experience</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mt-5 mt-lg-0 col-12">
            <div className="block-contents">
              <div className="section-title" id='about-us'>
                <h5>About Us</h5>
                <span>{header}</span>
                <h2>{subHeader}</h2>
              </div>
              <blockquote>{quoteText}</blockquote>
            </div>

            <p style={{whiteSpace:"pre-line"}} >{detailText}</p>

            {readMore ? (
              <Link to="/about" className="theme-btn mt-35">
                read more
              </Link>
            ) : null}
          </div>
        </div>
      </div>
      <h2 className="section-rotate-title d-none d-xxl-block">ABOUT</h2>
    </section>
  );
};

export default About;
