import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

import carouselData from "./carouselData";
import { Link } from "react-router-dom";

const OurClients = () => {
  return (
    <section className="portfolio-showcase-carosuel-wrapper fix section-padding">
      <div className="container block-contents">
        <div className="row">
          <div className="col-xl-6 col-lg-8 mb-4 offset-xl-3 offset-lg-2 col-12 text-center">
            <div className="section-title" id='partners'>
              <h2>Software Support</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-showcase-carousel-active">
        <Swiper
          // pagination={true}
          // modules={[Pagination]}
          autoplay={{
            delay: 2000, 
            disableOnInteraction: false, 
          }}
          slidesPerView={5}
          spaceBetween={15}
          loop
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 20,
              centeredSlides: true,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: true,
            },
            "@1.00": {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            "@1.50": {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
        >
          {carouselData.map((data) => (
            <SwiperSlide
              className="single-recent-portfolio bg-contain"
              key={data.id}
              style={{ backgroundImage: `url(${data.img})` }}
            >
              <div className="project-details">
                <h5>
                  <Link>{data.title}</Link>
                </h5>
                <span>{data.category}</span>
             
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default OurClients;
