import React from "react";
import CountUp from "react-countup";
import bannerImg from "../../../assets/Images/Background/funFact.png";

let data = [
  {
    subject: "Clients Globally",
    count: 100,
    postText: "+",
  },
  {
    subject: "Countries Served",
    count: 10,
    postText: "+",
  },
  {
    subject: "Expert & Engineers",
    count: 20,
    postText: "+",
  },
  {
    subject: "HAZID & ENVID",
    count: 25,
    postText: "+",
  },
  {
    subject: "HAZOP & PHA",
    count: 100,
    postText: "+",
  },
  {
    subject: "SIL Study",
    count: 25,
    postText: "+",
  },
  {
    subject: "HAC & ATEX",
    count: 25,
    postText: "+",
  },
  {
    subject: "QRA & FERA",
    count: 25,
    postText: "+",
  },
  {
    subject: "RAM & RCM",
    count: 10,
    postText: "+",
  },
  {
    subject: "F&G Mapping (2D/3D)",
    count: 20,
    postText: "+",
  },
  {
    subject: "AUDITS",
    count: 100,
    postText: "+",
  },
];

const FunFact = () => {
  return (
    <section
      className="fun-fact-banner bg-overlay text-white bg-cover"
      style={{ backgroundImage: `url(${bannerImg})` }}
    >
      <div className="container">
        <div className="row text-center text-md-start">
          {data.map((item) => {
            return (
              <div className="col-lg-3 col-md-6 mt-30 col-12">
                <div className="single-fun-fact">
                  <h2>
                    <CountUp end={item.count} duration={3}></CountUp>
                    {item.postText}
                  </h2>
                  <h3>{item.subject}</h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FunFact;
