import React from "react";
import bannerBg from "../assets/Images/Background/ContactUs.png";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import PageBanner from "../Components/VA_Components/PageBanner";
import ContactPageContents from "../Components/VA_Components/Contact/ContactContents";

const ContactPage = () => {
  return (
    <>
      <PageBanner
        title="Contact Us"
        bannerBg={bannerBg}
        currentPage="contact"
      />
      <ContactPageContents />
      {/* <BrandCarouselThree /> */}
    </>
  );
};

export default ContactPage;
