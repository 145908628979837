import React from "react";
import brand from "../../../assets/img/va_img/Clients_main.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { v4 as uuidv4 } from "uuid";
import brand1 from "../../../assets/img/va_img/company_logo_1.png";
import brand2 from "../../../assets/img/va_img/company_2.png";
import brand3 from "../../../assets/img/va_img/company_3.png";
import brand4 from "../../../assets/img/va_img/company_4.png";
import brand5 from "../../../assets/img/va_img/company_5.png";

import brand6 from "../../../assets/img/va_img/company_6.png";
import brand7 from "../../../assets/img/va_img/company_7.png";
import brand8 from "../../../assets/img/va_img/company_8.png";
import brand9 from "../../../assets/img/va_img/company_9.png";

const brandCarouselOneData = [
  {
    id: uuidv4(),
    brand: brand1,
    name: "Aviation",
  },

  {
    id: uuidv4(),
    brand: brand2,
    name: "Google",
  },

  {
    id: uuidv4(),
    brand: brand3,
    name: "Google",
  },

  {
    id: uuidv4(),
    brand: brand4,
    name: "Google",
  },

  {
    id: uuidv4(),
    brand: brand5,
    name: "Google",
  },
  {
    id: uuidv4(),
    brand: brand6,
    name: "Google",
  },  {
    id: uuidv4(),
    brand: brand7,
    name: "Google",
  },  {
    id: uuidv4(),
    brand: brand8,
    name: "Google",
  },  {
    id: uuidv4(),
    brand: brand9,
    name: "Google",
  },
];

const OurSkill = () => {
  const percentage1 = 94;
  const percentage2 = 88;

  return (
    <section className="we-work-skill-wrapper section-padding">
      <div className="container">
        <div className="row block-contents">
          <div className="col-xl-6 col-lg-8 mb-4 offset-xl-3 offset-lg-2 col-12 text-lg-center">
            <div className="block-contents text-center">
              <div className="section-title" id='clients'>
                <h5>Clients</h5>
                <span>We are</span>
                <h2>Unlocking Potential Across industries</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="col-xl-5 mt-5 col-md-5 offset-xl-1 mt-lg-4">
            <div className="brand-client-img">
              <img src={brand} alt="" />
              {/* <div className="company-year">
                <p>Since</p>
                <h2>2018</h2>
              </div> */}
            </div>
          </div>
          <div className="col-md-7 col-xl-6 mt-lg-4">
            <div className="our-achievement-skill-wrapper">
              <ul>
                <li>Aviation</li>
                <li>Offshore Cross Country Piplines</li>
                <li>Energy</li>
                <li>Food</li>
                <li>Industrial</li>
                <li>Logistics & Transportation</li>
                <li>Oil & Gas</li>
                <li>Petrochemical</li>
                <li>Waste and Environment</li>
              </ul>
              <p className="border-left">
                By partnering with Vigilant Ally, our clients shall gain access
                to the information they need to make clear decisions around
                investments to ensure process safety. With risk levels managed
                to ALARP, organizations can safeguard their reputation and
                enhance stakeholder relations, which helps to boost competitive
                advantage. Vigilant Ally’s Safety Studies are substantiated by
                grounded technical assessments, which enable companies to
                protect people, assets, and the environment while also
                optimising business performance.
              </p>
              <div className="skill-circle-progress-wrapper row mt-4">
                <div className="col-sm-12">
                  <h2>Our Fotre</h2>
                </div>
                <div className="col-sm-6">
                  <div className="single-skill-circle-bar">
                    <div className="contents d-inline-block">
                      <h6>
                        Quality Service
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-skill-circle-bar">
                    <div className="contents d-inline-block">
                      <h6>
                        Skilled and Experienced Team
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-skill-circle-bar">
                    <div className="contents d-inline-block">
                      <h6>
                        On Time Delivery
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-skill-circle-bar">
                    <div className="contents d-inline-block">
                      <h6>
                        Latest technology
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="global-client-carousel mt-5">
                <div className="global-title">
                  <h4>Global Clients</h4>
                </div>
                <div className="global-carousel mt-4">
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    loop
                    autoplay
                    // scrollbar={{ draggable: true }}
                    breakpoints={{
                      "@0.00": {
                        slidesPerView: 2,
                      },
                      "@0.75": {
                        slidesPerView: 3,
                      },
                      "@1.00": {
                        slidesPerView: 3,
                      },
                      "@1.50": {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {brandCarouselOneData.map((data) => (
                      <SwiperSlide className="single-brand-logo" key={data.id}  >
                        <img src={data.brand} alt={data.name} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSkill;
