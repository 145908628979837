import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { scrollToSection } from "../../../utils/utils";
import { useHistory } from "react-router-dom";

const Hero = ({ slides, disableCarousel = false }) => {
  return (
    <section className="hero-wrapper hero-2">
      <Swiper
        navigation={!disableCarousel}
        modules={disableCarousel ? [] : [Navigation, EffectFade]}
        effect={"fade"}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="hero-slider-2">
            <div
              className="single-slide bg-cover"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                    <div className="hero-contents pe-lg-3">
                      <h1
                        className="wow fadeInLeft animated"
                        data-wow-duration="1.3s"
                      >
                        {slide.mainText}
                      </h1>
                      <p
                        className="pe-lg-5 wow fadeInLeft animated"
                        data-wow-duration="1.3s"
                        data-wow-delay=".4s"
                      >
                        {slide.subText}
                      </p>
                      <Link
                        to="/contact"
                        className="theme-btn va me-sm-4 wow fadeInLeft"
                        data-wow-duration="1.2s"
                        data-wow-delay=".8s"
                      >
                        {slide.buttonText1}
                      </Link>
                      <Link
                        onClick={()=>scrollToSection("services")}
                        className="theme-btn va wow fadeInLeft"
                        data-wow-duration="1.2s"
                        data-wow-delay=".6s"
                      >
                        {slide.buttonText2}
                      </Link>
                    </div>
                  </div>
                  
                </div>
              </div>
              
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Hero;
