import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Preloader from "./Components/Preloader";
import ScrollTopBtn from "./Components/ScrollTopBtn";
import AboutPage from "./pages-Template/AboutPage";
import ServicePage from "./pages-Template/ServicePage";
import HomeOne from "./pages-Template/HomeOne";
import HomeTwo from "./pages-Template/HomeTwo";
import News from "./pages-Template/News";
import NewsDetails from "./pages-Template/NewsDetails";
import TeamPage from "./pages-Template/TeamPage";
import FaqPage from "./pages-Template/FaqPage";
import PricingPage from "./pages-Template/PricingPage";
import ServiceDetails from "./pages-Template/ServiceDetails";
import HomeThree from "./pages-Template/HomeThree";
import HomeFour from "./pages-Template/HomeFour";
import ProjectPage from "./pages-Template/ProjectPage";
import ContactPage from "./Pages/ContactPage";

import Home from "./Pages/Home";
import HazardAnalysis from "./Pages/ProcessSafety/HazardAnalysis";
import HSETraining from "./Pages/HSETraining/HSETraining";

import Header from "./Components/VA_Components/Header/Header";
import Footer from "./Components/VA_Components/Footer/Footer";
import HSEAudit from "./Pages/HSEAudits/HSEAudit";
import RiskAssessment from "./Pages/ProcessSafety/RiskAssessment";
import LossPrevention from "./Pages/ProcessSafety/LossPrevention";
import Environment from "./Pages/Environment/Environment";
import PSMTraining from "./Pages/PSMTraining/PSMTraining";
import FunctionalSafety from "./Pages/FunctionalSafety/FunctionalSafety";
import NotFound404 from "./Pages/404/NotFound404";
import VisualManagement from "./Pages/VMC/VisualManagement";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <div className="va">
        <ScrollTopBtn />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/process-safety/hazard-analysis" element={<HazardAnalysis />} />
          <Route path="/environment" element={<Environment/>} />
          <Route path="/PSM-training" element={<PSMTraining />} />
          <Route path="/functional-safety" element={<FunctionalSafety />} />
          <Route path="/HSE-audit" element={<HSEAudit />} />
          <Route path="/HSE-training" element={<HSETraining />} />

          <Route path="/visual-management-consultancy" element={<VisualManagement />} />
          {/* TODO: Remaining on Services page */}
          <Route path="/process-safety/risk-assessment" element={<RiskAssessment />} />
          <Route path="/process-safety/loss-prevention-activities" element={<LossPrevention/>} />

          <Route path="/contact" element={<ContactPage />} /> /

          <Route path="*" element={<NotFound404 />} />


          {/* <Route path="/" element={<HomeOne />} />
        <Route path="/homeTwo" element={<HomeTwo />} />
        <Route path="/homeThree" element={<HomeThree />} />
        <Route path="/homeFour" element={<HomeFour />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/newsDetails" element={<NewsDetails />} />
        <Route path="/servicesDetails" element={<ServiceDetails />} />
        <Route path="/news" element={<News />} />
        <Route path="/projects" element={<ProjectPage />} /> */}
        </Routes>
        <Footer></Footer>
      </ div>
    );
  }
}

export default App;