import React from "react";
import { Link } from "react-router-dom";
import FooterCta from "./FooterCta";
import FooterOneCopyrights from "./FooterOneCopyrights";
import footerMap from "../../../assets/Images/VA_Images/assets/img/footer-map.png";
import { scrollToTop } from "../../../utils/utils";

const Footer = () => {

  return (
    <footer className="footer-2 footer-wrap">
      <div className="footer-widgets-wrapper text-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
              <div className="single-footer-wid site_info_widget">
                <div className="wid-title">
                  <h3>Get In Touch</h3>
                </div>
                <div className="contact-us">
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-phone"></i>
                    </div>
                    <div className="contact-info">
                      <p>
                        <a href="tel:+91-951-837-9702"> +91-951 837 9702</a>
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-envelope"></i>
                    </div>
                    <div className="contact-info">
                      <p>
                        <a href="mailto: projects@vigilantally.com">
                          projects@vigilantally.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-download"></i>
                    </div>
                    <div className="contact-info">
                      <p>
                        <a
                          href="https://vigilantally.com/downloads/VA_pdf.pdf"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Download our Portfolio
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon overflow-visible">
                      <i className="fal fa-map-marker-alt"></i>
                    </div>
                    <div className="contact-info">
                      <p>
                        C7/4, Little Hearts, Kaka Halwai lane, Talegaon Dabhade,
                        Pune 410507
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
              <div className="single-footer-wid">
                <div className="wid-title">
                  <h3>Quick Links</h3>
                </div>
                <ul>
                  <li>
                    <Link
                      to="/process-safety/hazard-analysis"
                      onClick={scrollToTop}
                    >
                      Process Hazard Analysis
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/process-safety/loss-prevention-activities"
                      onClick={scrollToTop}
                    >
                      Loss Prevention Activities
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/process-safety/risk-assessment"
                      onClick={scrollToTop}
                    >
                      Risk Assessment
                    </Link>
                  </li>

                  <li>
                    <Link onClick={scrollToTop} to="/environment">
                      Environment
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/PSM-training">
                      PSM Training And Implementation
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/HSE-Audit">
                      HSE Audits
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      to="/visual-management-consultancy"
                    >
                      Visual Management Consultancy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollToTop} to="/hse-training">
                      HSE Training
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">
              <div className="single-footer-wid newsletter_widget">
                <div className="wid-title">
                  <h3>Globally Serviced In</h3>
                </div>
                <div className="map-location">
                  <img src={footerMap} alt="">
                    {/* <i class="bi bi-geo-alt-fill"></i> */}
                  </img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCta />

      {/* <FooterOneCopyrights/> */}
    </footer>
  );
};

export default Footer;
