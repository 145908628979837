import React from "react";
import { Link } from "react-router-dom";
import { scrollToSection } from "../../../utils/utils";

const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
  return (
    <div className={mobileMenu ? "mobile-nav" : "mobile-nav show"}>
      <button onClick={handleMobileMenu} type="button" className="close-nav">
        <i className="fal fa-times-circle"></i>
      </button>
      <nav className="sidebar-nav">
        <ul className="metismenu" id="mobile-menu">
          {/* <li><a className="has-arrow" href="#">Homes</a>
                    <ul className="sub-menu">
                        <li><Link to="/">homepage 1</Link></li>
                        <li><Link to="/homeTwo">homepage 2</Link></li>
                        <li><Link to="/homeThree">homepage 3</Link></li>
                        <li><Link to="/homeFour">homepage 4</Link></li>
                    </ul>
                </li>
                <li><Link to="/about">about us</Link></li>
                <li><Link to="/services">Services</Link></li>
                <li>
                    <a className="has-arrow" href="#">Pages</a>
                    <ul className="sub-menu">
                        <li><Link to="/faq">faq</Link></li>
                        <li><Link to="/pricing">Pricing</Link></li>
                        <li><Link to="/team">Team</Link></li>
                    </ul>
                </li>
                <li><Link to="/news">News</Link></li>
                <li><Link to="/contact">Contact</Link></li> */}

          <li>
            <Link to="/" onClick={handleMobileMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/#about-us"
              onClick={() => {
                scrollToSection("about-us");
                handleMobileMenu();
              }}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/#services"
              onClick={() => {
                scrollToSection("services");
                handleMobileMenu();
              }}
            >
              Services{" "}
            </Link>
            <ul className="sub-menu">
              <li>
                <Link>Process Safety </Link>
                <ul className="sub-menu">
                  <li>
                    <Link
                      to="/process-safety/hazard-analysis"
                      onClick={handleMobileMenu}
                    >
                      Process Hazard Analysis
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/process-safety/risk-assessment"
                      onClick={handleMobileMenu}
                    >
                      {" "}
                      Risk Assessment
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/process-safety/loss-prevention-activities"
                      onClick={handleMobileMenu}
                    >
                      Loss Prevention Activities
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/environment" onClick={handleMobileMenu}>
                  Environment
                </Link>
              </li>
              <li>
                <Link to="/PSM-training" onClick={handleMobileMenu}>
                  PSM Training And Implementation
                </Link>
              </li>
              <li>
                <Link to="/functional-safety" onClick={handleMobileMenu}>
                  Functional Safety{" "}
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link
                      to="/functional-safety#sil"
                      onClick={() => {
                        scrollToSection("sil");
                        handleMobileMenu();
                      }}
                    >
                      Safety Integrity Level (SIL)
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/functional-safety#FMEA-&-FMECA"
                      onClick={() => {
                        scrollToSection("FMEA-&-FMECA");
                        handleMobileMenu();
                      }}
                    >
                      Failure Modes & Effects Analysis (FMEA) & Failure Modes,
                      Effect & Criticality Analysis (FMECA)
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/functional-safety#RAM"
                      onClick={() => {
                        scrollToSection("RAM");
                        handleMobileMenu();
                      }}
                    >
                      Reliability Availability Maintainability (RAM)
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/functional-safety#RCM"
                      onClick={() => {
                        scrollToSection("RCM");
                        handleMobileMenu();
                      }}
                    >
                      Reliability Centered Maintenance (RCM){" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/functional-safety#MAH-&-SCE"
                      onClick={() => {
                        scrollToSection("MAH-&-SCE");
                        handleMobileMenu();
                      }}
                    >
                      Major Accident Hazard (MAH) & Safety Critical Element
                      (SCE)
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/HSE-audit#hse-audit"
                  onClick={() => {
                    scrollToSection("hse-audit");
                    handleMobileMenu();
                  }}
                >
                  HSE Audits{" "}
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link
                      to="/HSE-audit#process-safety-audit"
                      onClick={() => {
                        scrollToSection("process-safety-audit");
                        handleMobileMenu();
                      }}
                    >
                      Process Safety
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/HSE-audit#fire-&-safety-audit"
                      onClick={() => {
                        scrollToSection("fire-&-safety-audit");
                        handleMobileMenu();
                      }}
                    >
                      Fire & Electrical Safety
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/HSE-audit#environment-compliance-audit"
                      onClick={() => {
                        scrollToSection("environment-compliance-audit");
                        handleMobileMenu();
                      }}
                    >
                      Environmental Compliance
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/HSE-audit#occupational-health-&-safety-audit"
                      onClick={() => {
                        scrollToSection("occupational-health-&-safety-audit");
                        handleMobileMenu();
                      }}
                    >
                      Occupational Health & Safety{" "}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/visual-management-consultancy" onClick={handleMobileMenu}>Visual Management Consultancy</Link>
              </li>
              <li>
                <Link to="/HSE-training" onClick={handleMobileMenu}>
                  HSE Training
                </Link>
              </li>
            </ul>
          </li>
          {/* <li>
            <Link to="/news">Process</Link>
          </li> */}
          <li>
            <Link
              to="/#clients"
              onClick={() => {
                scrollToSection("clients");
                handleMobileMenu();
              }}
            >
              Clients
            </Link>
          </li>
          <li>
            <Link
              to="/#partners"
              onClick={() => {
                scrollToSection("partners");
                handleMobileMenu();
              }}
            >
              Software Support
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleMobileMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      <div className="action-bar">
        <a href="mailto: projects@vigilantally.com">
          <i className="fal fa-envelope"></i>projects@vigilantally.com
        </a>
        <a href="tel:+91-951-837-9702">
          <i className="fal fa-phone"></i>+91-951 837 9702
        </a>
        <Link
          to="/contact"
          onClick={handleMobileMenu}
          className="d-btn theme-btn"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
