import React from 'react'
import { Link } from 'react-router-dom'
import { scrollToSection } from '../../utils/utils'
import vmc from "../../assets/img/va_img/vmc.jpeg";

function VisualManagement() {
  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-1 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={() => scrollToSection("vmc")}>
                      Visual Management Consultancy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={vmc} alt="sVisual Management Consultancy" />
              <h2 id="vmc">Visual Management Consultancy</h2>
              <p>
                We are helping organizations improve their processes,
                communication, and overall efficiency by implementing visual
                management techniques and strategies. Visual management is a
                concept that emphasizes the use of visual aids, tools, and
                displays to communicate information, monitor performance, and
                facilitate decision-making within a workplace.
              </p>

              <div className="va-content-list style-2">
                <p>
                  We are offering:
                  <ul>
                    <li>
                      <b>Process Analysis:</b> Assessing existing processes to
                      identify areas where visual management techniques can be
                      implemented to enhance clarity, transparency, and
                      efficiency.
                    </li>

                    <li>
                      <b>Communication Improvement:</b> Providing tailor-made
                      needs and challenges of each organization. We work closely
                      with the organization's leadership to develop custom
                      visual solutions.
                    </li>

                    <li>
                      <b>Custom Solutions:</b> Providing tailor-made needs and
                      challenges of each organization. We work closely with the
                      organization's leadership to develop custom visual
                      solutions.
                    </li>

                    <li>
                      <b>Continuous Improvement:</b> We will be helping
                      organizations to establish a culture of continuous
                      improvement by incorporating visual techniques to identify
                      inefficiencies and implement changes.
                    </li>

                    <li>
                      <b>Change Management:</b> Implementing visual management
                      techniques can sometimes require changes to established
                      processes and habits. We can assist in managing the change
                      process and overcoming resistance to new methods.
                    </li>

                    <li>
                      <b>Remote Work Adaptation:</b> With the rise of remote
                      work, we can also help organizations to adapt visual
                      management techniques to virtual environments, using
                      digital tools and platforms.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>

              <div className="checked-features-list style-2">
                        <p>
                          <b>Visual Tools:</b> Designing and implementing
                          various visual tools such as{" "}
                        </p>
                        <ul>
                          <li>Area Identification Boards</li>
                          <li>Safety Signs</li>
                          <li>Policies and Instructions</li>
                          <li>Display Posters</li>
                          <li>Compliance Boards </li>
                          <li>Company / Title Boards </li>
                          <li> Tags and Labels</li>
                          <li> Informative Boards</li>
                          <li> Good Practices Products</li>
                          <li>Knowledge Hub, and other visual aids </li>
                        </ul>

                        <br></br>
                        <p>
                          <b>Installation Type: </b>
                        </p>
                        <ul>
                          <li>Sticker Mounting.</li>
                          <li>Wall Mounting.</li>
                          <li>Clamp Fitting</li>
                          <li> Stand Mounting. (without Civil Work)</li>
                          <li> Pylon Mounting.</li>
                          <li>Floor Marking Tape. </li>
                          <li>Speed Breaker Mounting. </li>
                          <li>Convex Mirror Mounting with Fitting. </li>
                          <li>Convex Mirror Mounting with Fitting & Pole. </li>
                        </ul>

                        <br></br>
                        <p>
                          <b>Display Material Qualities:</b>
                        </p>
                        <ul>
                          <li> Poster Paper</li>
                          <li> Plastic Lamination </li>
                          <li>Vinyl Sticker </li>
                          <li> Autoglow sticker </li>
                          <li> Flex</li>
                          <li>Sunboard (Various Thicknesses) </li>
                          <li> Galvanized Sheet</li>
                          <li> ACP (Aluminium Composite Panel)</li>
                          <li> Acrylic (Various Thicknesses)</li>
                          <li> White Board</li>
                          <li> LED Photo Frame</li>
                          <li> Stainless steel Engraving</li>
                          <li> Stainless steel Itching</li>
                        </ul>
                      </div>
            </div>
            <div className="section-divider"></div>

            {/* <div className="faq-content pl-0 pl-md-4">
            <h2>Common Question for this project</h2>
          </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default VisualManagement