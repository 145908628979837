import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/Images/Features/740x460/healthTraining.jpeg";
import mopoMatrix from "../../assets/Images/Misc/Mopo.png";
import image3 from "../../assets/img/gallery/6.jpg";

const HSETraining = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-1 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={() => scrollToSection("hse-training")}>
                      HSE Training
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={image} alt="" />
              <h2 id="hse-training">HSE Training</h2>
              <p>
                HSE training courses designed by Vigilant Ally help clients to
                understand and appreciate the hazards, risks, and mitigating
                control measures and safe work practices. It has been aimed that
                training modules will be need-based and specifically tailor-made
                to the processes, operations, and activities carried out by
                individual customers pertaining to the industry & sector.
              </p>
              <div className="va-content-list style-2">
                <p>
                  Objective of HSE Training:
                  <ul>
                    <li>
                      Analysis of the process at an early stage with a view of
                      ensuring that the process design accounts for credible
                      hazardous scenarios.
                    </li>
                    <li>
                      Examine the safeguards involved in the design of the
                      process which are designed to alleviate the relevant risk
                      for the identified hazardous scenarios.
                    </li>
                    <li>
                      To ensure the quality and effectiveness of the
                      study/workshop, it is important to call for a
                      multidisciplinary team familiar with the process and its
                      operations.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
            </div>
            {/* <div className="faq-content pl-0 pl-md-4">
              <h2>Common Question for this project</h2>
         
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HSETraining;
