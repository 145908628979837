import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import riskAssessment from "../../assets/Images/Misc/riskAssessment.png";

const RiskAssessment = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-1 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={() => scrollToSection("risk")}>
                      Risk Assessment
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("QRA")}>
                      01. Quantitative / Qualitative Risk Assessment (QRA) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("GSD")}>
                      02. Gas and Smoke Dispersion Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("FERA")}>
                      03. Fire, Explosion & Risk Assessment (FERA) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("FRD")}>
                      04. Flare Radiation and Dispersion Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("EERA")}>
                      05. Escape, Evacuation & Rescue Analysis (EERA) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("ALARP")}>
                      06. As Low As Reasonably Practicable Demonstration (ALARP)
                      Study
                    </Link>
                  </li>

                  {/* <li>
                    <Link onClick={() => scrollToSection("conclusion")}>
                      Conclusion
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={riskAssessment} alt="" />

              <h2 id="risk">Risk Assessment</h2>
              <p>
                Understanding risk is vital for sound and cost-effective
                decision-making and for establishing a technical risk picture
                for the entire asset lifecycle.
              </p>
              <p>
                Risk assessment includes identification of risks, deciding the
                impact of risk, evaluation of risks by giving consideration to
                suitable existing controls & determining whether the risk is
                acceptable or not and implementing preventive/ mitigation
                measures to ensure operation of the facility in an effective
                manner for the entire lifecycle.
              </p>
              <p>
                Risk assessment and hazard studies are a required element of
                facility design, construction, and operation. Industrial
                licensing authorizes and insurance companies establish these
                requirements to ensure that companies are working to minimize
                possible dangers to staff and equipment and ensure that
                everything is operating according to the original design intent.
              </p>
              <div className="section-divider"></div>
              <h2 id="QRA">
                Quantitative / Qualitative Risk Assessment (QRA) Study
              </h2>
              <p>
                A QRA is a formal and systematic study to estimate the
                likelihood and consequences of hazardous events and pressing the
                results quantitatively as risk to people, the environment or
                asset. It also assesses the robustness and validity of
                quantitative results, by identifying critical assumptions and
                risk driving factors.
              </p>
              <p>
                The study is typically required for the high pressure pipelines,
                storage facilities and importation sites, Production and for all
                processing facilities. Vigilant Ally provides a worldwide
                interdisciplinary network of consultants and offers a full scope
                of quantitative risk assessment, with optional consolidation of
                complete consequences modelling.
              </p>
              <div className="section-divider"></div>
              <h2 id="GSD">Gas and Smoke Dispersion Study</h2>
              <p>
                Gas and Smoke Dispersion Analysis study is performed to evaluate
                or calculate the quantity of the material that is released to
                the atmosphere and to identify the risk and hazard associated
                with the dispersed material.
              </p>
              <p>
                The analysis starts with identification of possible release
                scenarios and then modelling them using sophisticated computer
                tools to generate results in terms of dispersion contours, gas
                footprint or time dependent cloud movement and growth.Another is
                to evaluate the Risk of personnel who work near the Gas release
                places like Vent, PSV. In order to identify the proper location
                and height of the vent that releases smoke or gas to the
                atmosphere.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The objective of the study is as follow:
                  <ul>
                    <li>Identifying potential release scenarios</li>
                    <li>
                      Performing modelling exercise for identified scenarios
                      using dedicated software tool
                    </li>
                    <li>
                      Interpretation of results of consequences (flammable &
                      toxic gas dispersion)
                    </li>
                    <li>
                      Suggesting measures for mitigation of hazards, wherever
                      applicable
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="FERA">Fire, Explosion & Risk Assessment (FERA) Study</h2>
              <p>
                Fire & Explosion Risk Assessment (FERA) included quantification
                of the probability of fire & explosion hazards and their
                consequences. FERA shall evaluate the risks to the assets
                associated with the event caused by Loss of Contaminants (LoCs).
                The results of this assessment are used to ensure safe facility
                layouts, specify active fire protection and (AFP), Passive Fire
                Protection (PFP), blast requirements and to provide the inputs
                for Escape, Evacuation Rescue analysis (EERA), Building Risk
                Assessment (BRA) and Quantitative Risk Assessment (QRA) Studies.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The objectives of the study are as follows:
                  <ul>
                    <li>
                      Identification of all possible fire and explosion hazards
                      on the facilities.
                    </li>
                    <li>
                      To identify flammable inventory based on physical
                      properties
                    </li>
                    <li>
                      To find out probable release types and subsequent
                      fire/explosion scenarios
                    </li>
                    <li>
                      To find out the leak frequency associated with each
                      fire/explosion scenario
                    </li>
                    <li>
                      Analysis of results of consequences (fire & explosion)
                    </li>
                    <li>
                      Carry out modeling for the identified scenario using a
                      dedicated software tool
                    </li>
                    <li>
                      To find out the potential escalation scenarios and
                      consequences of each event, with consideration of the
                      control and mitigation measure proposed and
                    </li>
                    <li>
                      Proposed additional measures for control and mitigation of
                      the fire / explosion hazard for review as part of ALARP
                      demonstration process.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="FRD">Flare Radiation and Dispersion Study</h2>
              <p>
                Flare Dispersion and Radiation study is the structured and
                systematic approach which helps to properly design the stack of
                flares, determine the heat radiation and noise levels of
                subsonic or sonic flares, and to find out the flue gas
                composition and the effective stack parameters for atmospheric
                dispersion applications
              </p>
              <p>
                The objective of the analysis is to evaluate the atmospheric
                impact of industrial flare with respect to stack height and
                diameter in order to minimize risk for each and every individual
                and nearby vicinity, it allows to determine the thermal impact
                and acoustic impact of existing or future flares
              </p>
              <p>
                Flare and process vent design needs careful consideration of
                hazard and risk factors such as thermal radiation, flammable
                dispersion and noise. These factors are required to be assessed
                to define & validate suitable stack height, separation from
                adjacent facilities and to define any sterile zone.
              </p>
              <p>
                Flares produce a significant amount of heat radiation, emit
                atmospheric pollutants and poorly designed flares present danger
                to all individuals working in the facility. Proper dispersion of
                hazardous vapors from flares and vents is a general requirement.
                The software used to perform the robust dispersion modelling to
                define the hazardous area zone extent of flammable components in
                line with BS EN 60079 and API RP 505
              </p>
              <p>
                The important aspects of the design and related assembly are
                governed by the nature of combustion products, the dispersion
                envelope of combustion products or the gases subjected to
                flaring in case of cold-flaring, the radiations generated due to
                flaring and the noise generation. A dedicated analysis can be
                performed for designing the flame and the flare tip diameter and
                height of flare stack can be verified.
              </p>
              <div className="section-divider"></div>
              <h2 id="EERA">
                Escape, Evacuation & Rescue Analysis (EERA) Study
              </h2>
              <p>
                EERA study is the approach to avoid injuries and fatalities in
                the event of accidents and emergency situations. The basic
                objective of the study is to assure the evacuation means can be
                used and that all workers within the platform can be evacuated
                in the event of major accident hazard (MAHs).
              </p>
              <p>
                Adequate facilities for the safe and complete evacuation, escape
                and rescue of all the personnel is one of the key aspects which
                is why Evacuation, Escape and Rescue Analysis (EERA) have come
                into forefront to evaluate the performance of emergency response
                facilities and procedures in case of any emergency. It is
                attempted in conjunction with Consequence Analysis & QRA.
              </p>
              <p>
                It is vital to ensure that EER means are available, accessible
                enough, robust, sufficient and reliable to withstand the effects
                of accidental events. EER provisions are implemented for a new
                facility and existing facility, whichever with due consideration
                of factors that influence EER and means.
              </p>
              <p>
                The Study is applicable to new/existing onshore and offshore
                facilities/installations. The Study shall be carried out at
                Front End Engineering Design, Plant Design and Detailed
                Engineering. Understanding how an accident/incident impairs the
                EER means in the early stages of design saves money and time to
                implant the necessary changes prior to construction.
              </p>

              <div className="section-divider"></div>
              <h2 id="ALARP">
                As Low As Reasonably Practicable Demonstration (ALARP) Study
              </h2>
              <p>
                ALARP Demonstration is generally known as “As Low as Reasonably
                Practicable”. Reasonably practicable includes a risk with
                respect to trouble, time and money needed to control it. Hence
                ALARP describes the extent to which we expect to see workplace
                risks controlled. For a risk to be ALARP it should be reasonable
                to demonstrate that cost involved in reducing the risk further
                would be inadequate to the benefit gained. The ALARP principle
                comes into picture when unbounded time, attempts and money could
                be paid out in the attempt of reducing a risk to null. It should
                not be understood as simply a qualitative measure of benefit
                against detriment. It is more a common practice of judgment of
                the balance of risk and societal benefit.
              </p>
              <div className="checked-features-list style-2">
                <p>
                  The following elements are considered when decided whether or
                  not a risk is tolerable.
                  <ol>
                    <li>Health & Safety Guideline</li>
                    <li>The specification</li>
                    <li>International standards and laws</li>
                    <li>Suggestions from advisory bodies</li>
                    <li>
                      Comparison with similar hazardous event in other
                      industries
                    </li>
                  </ol>
                </p>
              </div>
              <p>
                One more factor that often comes into the ALARP principle is the
                cost of evaluating the improvement gained in an attempted risk
                reduction (Cost Benefit Analysis). In an utmost complex system,
                this can be on a very higher side, and it could be a limiting
                factor in practicability of risk reduction, even though
                according to HSE guidelines, cost alone should never be a reason
                for taking extra safety risks.
              </p>
              <div className="section-divider"></div>
              {/* <h2 id="conclusion">Conclusion</h2>
              <p>
                A comprehensive risk assessment process is indispensable for
                informed decision-making and ensuring the safety and
                effectiveness of assets throughout their lifecycle. By
                identifying, evaluating, and mitigating risks, organizations can
                minimize potential dangers, protect their staff and equipment,
                and adhere to regulatory requirements.
              </p>
              <p>
                From quantitative and qualitative risk assessments to hazard
                studies, gas dispersion analyses, fire and explosion
                assessments, flare radiation studies, evacuation analyses, and
                ALARP demonstrations, a systematic approach is taken to
                comprehensively understand and manage potential hazards. Through
                diligent consideration of factors such as health and safety
                guidelines, international standards, cost-benefit analyses, and
                expert recommendations, the risk assessment process guides the
                design, construction, and operation of facilities, ultimately
                contributing to a safer and more resilient industrial landscape.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RiskAssessment;
