import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";

const ContactPageContents = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateNumber = (number) => {
    const re = /^(\+\d{1,3}-?)?(\d{3}-?)?\d{3}-?\d{4}$/;
    return re.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, phone, subject, message } = formData;
    let formValid = true;
    const newErrors = { ...errors };

    if (name.trim() === "") {
      newErrors.name = "Name is required";
      formValid = false;
    } else {
      newErrors.name = "";
    }

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
      formValid = false;
    } else {
      newErrors.email = "";
    }

    if (!validateNumber(phone)) {
      newErrors.phone = "Invalid phone number";
      formValid = false;
    } else {
      newErrors.phone = "";
    }

    if (subject.trim() === "") {
      newErrors.subject = "Subject is required";
      formValid = false;
    } else {
      newErrors.subject = "";
    }

    if (message.trim() === "") {
      newErrors.message = "Message is required";
      formValid = false;
    } else {
      newErrors.message = "";
    }

    setErrors(newErrors);

    if (formValid) {
      try {
        emailjs.init("Q9WAS0DDjumP-plrZ");
        let response = await emailjs.send(
          "service_gomzclo",
          "template_uxtnbgb",
          {
            name: name,
            email,
            message,
            subject,
            phone,
          }
        );

        if (response.status === 200) {
          toast.success(
            "Thank you for your interest. We will get back to you shortly."
          );
        }

        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });

        // const response = await fetch("mail.php", {
        //     method: "POST",
        //     body: new FormData(e.target)
        // });

        // if (response.ok) {
        //     const result = await response.text();
        //     if (result === "success") {
        //         console.log("Form submitted successfully");
        //     } else {
        //         console.error("Form submission failed");
        //     }
        // } else {
        //     console.error("HTTP error:", response.status);
        // }
      } catch (error) {
        toast.error("Something went wrong. Please try again later.");
        console.error("Error:", error);
      }
    }
  };

  const handleAddressClick = () => {
    const latitude = "18.7268788";
    const longitude = "73.6798023";
    const mapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;

    window.open(mapsLink, "_blank");
  };
  const handlePhoneClick = () => {
    window.location.href = "tel:+919890207600";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:projects@vigilantally.com";
  };

  return (
    <div className="contact-us-wrapper section-padding">
      <Toaster />
      <div className="container">
        <div className="row eq-height">
          <div className="col-lg-8 col-12">
            <div className="contact-form">
              <h2>Get in Touch</h2>
              <form
                // action="../../../server/mail.php"
                // method="POST"
                className="row"
                id="contact-form"
                onSubmit={handleSubmit}
              >
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="error-message">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <span className="error-message">{errors.phone}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    {errors.subject && (
                      <span className="error-message">{errors.subject}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <textarea
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {errors.message && (
                      <span className="error-message">{errors.message}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <input
                    className="submit-btn"
                    type="submit"
                    value="Submit Now"
                  />
                </div>
              </form>
              <span className="form-message" />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h2>CONTACT INFO</h2>
                <div className="va single-info">
                  <div className="icon" onClick={handleEmailClick}>
                    <i className="flaticon-email" />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <h5 onClick={handleEmailClick}>
                      projects@vigilantally.com
                    </h5>
                  </div>
                </div>
                <div className="va single-info">
                  <div className="icon" onClick={handlePhoneClick}>
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <h5 onClick={handlePhoneClick}>+91-989 020 7600</h5>
                  </div>
                </div>
                <div className="va single-info">
                  <div className="icon">
                    <i className="flaticon-pin" />
                  </div>
                  <div className="text" onClick={handleAddressClick}>
                    <span>Location</span>
                    <h5 onClick={handleAddressClick}>
                      C7/4, Little Hearts, Kaka Halwai lane, Talegaon Dabhade,
                      Pune 410507
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPageContents;
