import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import audit from "../../assets/Images/Features/740x460/healthAudit.webp";
import mopoMatrix from "../../assets/Images/Misc/Mopo.png";
import firePieChart from "../../assets/Images/VA_Images/assets/img/fires-pie-chart.png";
import { scrollToSection } from "../../utils/utils";

const HSEAudit = () => {
  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-1 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={() => scrollToSection("hse-audit")}>
                      HSE Audit
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => scrollToSection("process-safety-audit")}
                    >
                      Process Safety Audit
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={() => scrollToSection("fire-&-safety-audit")}
                    >
                      Fire & Electrical Safety Audit
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() =>
                        scrollToSection("environment-compliance-audit")
                      }
                    >
                      Environmental Compliance Audit
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() =>
                        scrollToSection("occupational-health-&-safety-audit")
                      }
                    >
                      Occupational Health & Safety Audit
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-2 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={audit} alt="" />
              <h2 id="hse-audit">HSE Audit</h2>
              <p>
                A Health Safety, and Environmental (HSE) audit is a foremost
                tool for ensuring the proper compliance with all objectives,
                priorities, and management concerns, as well as determining the
                efficacy, productivity, and reliability of policies and
                procedures in regard to their relation of HSE activities. HSE
                audits examine each stage in the occupational health & safety
                (OHS) management system by measuring compliance with the
                controls the organization has developed, with the ultimate aim
                of assessing their effectiveness and their validity for the
                future.
              </p>
              <p>
                We cater a range of health, safety, and environmental audits
                which are designed to guide our consumers through the latest and
                the best practice and legislation to be followed for the safest
                operations. Vigilant Ally is having in-house highly experienced
                team of HSE auditors with knowledge and experience from all
                around the world and with multiple sectors, helping our client
                to improve overall performance.
              </p>
              <div className="section-divider"></div>
              <h2 id="process-safety-audit">Process Safety Audit</h2>
              <p>
                It is necessary to review the efficiency of procedures and
                methods followed on Process Safety. Process Safety Audit is
                defined as “the structured process of collecting independent
                information on the efficiency, effectiveness, and reliability of
                the total process safety system and drawing up a plan for
                corrective action”
              </p>
              <p>
                Vigilant Ally reviews facilities and activities from a Process
                Safety point of view and find lacuna in current level of
                prevention, protection, and emergency management preparedness in
                order to suggest preventive measures to upgrade safety inputs to
                mitigate major consequences.
              </p>
              <div className="va-content-list style-2">
                <p>
                  Vigilant Ally shall :
                  <ul>
                    <li>
                      highlight the process hazards which can lead to severe
                      repercussions
                    </li>
                    <li>assess the gaps form the angle of process safety</li>
                    <li>
                      provide inputs to ameliorate the existing process
                      conditions
                    </li>
                    <li>
                      suggest preventive measures in order to limit the
                      identified hazards
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              {/* Fire & Electrical Safety Audit */}
              <h2 id="fire-&-safety-audit">Fire & Electrical Safety Audit</h2>
              <p>
                <b>Fire Safety Audit</b> is essential tool for evaluating fire
                safety standards of an occupancy in order to find the
                improvement areas and prepare an action plan. A structured and
                comprehensive Fire Safety Audit helps in identifying Fire
                Hazards and adequacy of existing Firefighting & detection
                system. It is indispensable part of loss prevention strategy.
              </p>
              <p>
                Study of more than 19,000 fires in industrial plants revealed
                the following factors contributing to fire:
              </p>
              <div className="align-center">
                <img
                  src={firePieChart}
                  alt="Study of Fire's in Industrial Plant"
                />
              </div>
              <div className="checked-features-list style-2">
                <p>
                  Five fundamental principles of fire prevention and reduction
                  are:
                  <ul>
                    <li>Fire prevention Engineering</li>
                    <li>Regular period inspection</li>
                    <li>Early detection</li>
                    <li>Fire control</li>
                    <li>Prevention of personal injuries, Emergency plan</li>
                  </ul>
                </p>
              </div>
              <div className="va-content-list style-2">
                <p>
                  Vigilant Ally shall
                  <ul>
                    <li>
                      Identify and Evaluate the possibility of occurrence of
                      Fire
                    </li>
                    <li>
                      Assess the prevailing control measures and suggest the
                      additional measure required
                    </li>
                    <li>
                      Check the Adequacy of Fire protection and detection system
                      as per applicable standards
                    </li>
                    <li>
                      Recommend improvement areas in Safety Management System to
                      mitigate fire emergencies
                    </li>
                  </ul>
                </p>
              </div>
              <p>
                <b>Electrical Safety Audit</b> assess the condition of existing
                Electrical Installations, identify Electrical Hazards in order
                to reduce the Fire risk and helps in ensuring the compliance.
              </p>
              <div className="va-content-list style-2">
                <p>
                  Vigilant Ally shall
                  <ul>
                    <li>
                      Verify maintenance practices as well as statutory
                      compliances such as CEA 2010, NFPA and State factory rules
                    </li>
                    <li>
                      Check Electrical documents such as SLD, Test records,
                      Thermography reports, Data sheets of critical
                      installations, etc.
                    </li>
                    <li>
                      Physically verify Electrical control system, Electrical
                      distribution network, Warning signage & Labelling,
                      Emergency system, etc.
                    </li>
                    <li>
                      Identify potential Electrostatic Hazards and the role of
                      Electrical safety in the overall Safety system
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              {/* Environmental Compliance Audit */}
              <h2 id="environment-compliance-audit">
                Environmental Compliance Audit
              </h2>
              <p>
                Environment Compliance Audit covers review and assessment of
                regulatory, obligatory and statutory requirements applicable to
                company without liaisoning with statutory & regulatory bodies
              </p>
              <div className="checked-features-list style-2">
                <p>
                  It covers the audit of the following.
                  <ul>
                    <li>Environment Legal compliance management</li>
                    <li>
                      Legal register and statutory returns/reports submission
                    </li>
                    <li>Laws relating to Air Pollution</li>
                    <li>Laws relating to Water Pollution</li>

                    <li>Public Liability Insurance Act & Rules</li>

                    <li>
                      Preparedness review on proposed/upcoming Legislation
                    </li>

                    <li>
                      Chemical Accidents (Emergency response, preparedness &
                      planning) Rules
                    </li>

                    <li>
                      Environment Management System(EMS) Audit covering the
                      process of legal compliance management
                    </li>

                    <li>
                      Other Legislations on Biomedical, Plastic, and e-Waste,
                      Hazardous Chemicals, Batteries Handling & Management, and
                      Industrial Disaster
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              {/* Environmental Compliance Audit */}
              <h2 id="occupational-health-&-safety-audit">
                Occupational Health & Safety Audit
              </h2>
              <p>
                Safety Audit shall be carried out under IS 14489:1998 (Indian
                Standard on Codes of Practices for Occupational Safety & Health
                Auditing), The Factories Act, 1948, the Explosive Act, 1884, the
                Boilers Act, 1923 and the Environment (Protection) Act, 1986,
                The SMPV rules, The Gas Cylinder Rules, the Electricity Act and
                Rule, etc.
              </p>
              <div className="checked-features-list style-2">
                <p>
                  The scope of the Safety audit is:
                  <ul>
                    <li>
                      to conduct a systematic critical assessment of all
                      potential hazards affecting persons, plant, services, and
                      operating procedures.
                    </li>
                    <li>
                      to investigate the working conditions of plants that are
                      inherently hazardous and may cause accidents.
                    </li>
                    <li>
                      to evaluate current work practises in terms of the health
                      and safety management system and make recommendations for
                      improvements
                    </li>
                  </ul>
                </p>
              </div>
              <div className="va-content-list style-2">
                <p>
                  Vigilant Ally shall audit following elements:
                  <ul>
                    <li>Health and Safety Policy</li>
                    <li>Safety Organisation and Functions</li>
                    <li>Safety Documentation</li>
                    <li>Safety Committee</li>
                    <li>Safety Budget</li>
                    <li>Accident Reporting, Investigation and Analysis</li>
                    <li>Safety Inspection</li>
                    <li>Safety Education and Training</li>
                    <li>Safety Communication/ Motivation/ Promotion</li>
                    <li>First Aid</li>
                    <li>Occupational Health Centre</li>
                    <li>House Keeping</li>
                    <li>Noise </li>
                    <li>Ventilation </li>
                    <li>Illumination</li>
                    <li>Hazard Identification and Control</li>
                    <li>Safe Operating Procedure</li>
                    <li>Work Permit system</li>
                    <li>Waste Disposal System</li>
                    <li>Personal Protective Equipment</li>
                    <li>Fire Prevention & Protection</li>
                    <li>Emergency Preparedness</li>
                    <li>Plant Layout and Hazardous Area Classification</li>
                    <li>Static Electricity</li>
                    <li>Lifting Machines and Tackles</li>
                    <li>Tank Storage Vessel Area</li>
                    <li>Colour Coding for Pipelines</li>
                    <li>Maintenance System</li>
                    <li>Transportation of Hazardous Material/Chemical</li>
                  </ul>
                </p>
              </div>{" "}
              <div className="section-divider"></div>
            </div>

            {/* <div className="faq-content pl-0 pl-md-4">
              <h2>Common Question for this project</h2>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HSEAudit;
