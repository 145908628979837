import React, { useState } from "react";
import HeaderTop from "./HeaderTop";
import mainLogo from "../../../assets/Images/VA_Images/Logo/mainLogo-removebg.png";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import { scrollToSection } from "../../../utils/utils";

const Header = () => {
  const [search, setSearch] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <header className="header-wrap header-2">
      {/* <HeaderTop/> */}
      <div className="main-header-wrapper">
        <div className="container-fluid align-items-center justify-content-between d-flex">
          <div className="logo">
            <Link to="/">
              <img src={mainLogo} alt="logo" />{" "}
            </Link>
          </div>
          <div className="main-menu d-none d-lg-block">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link
                  to="/#about-us"
                  onClick={() => scrollToSection("about-us")}
                >
                  About
                </Link>
              </li>
              {/* TODO: Fix and add sub links for the 3 given links */}
              <li>
                <Link
                  to="/#services"
                  onClick={() => scrollToSection("services")}
                >
                  Services <i className="fa fa-arrow-right"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link>
                      Process Safety <i className="fa fa-arrow-right"></i>
                    </Link>
                    {/* Process Hazard Analysis */}
                    <ul className="sub-menu">
                      <li>
                        <Link to="/process-safety/hazard-analysis">
                          Process Hazard Analysis{" "}
                          <i className="fa fa-arrow-right"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#hazid"
                              onClick={() => scrollToSection("hazid")}
                            >
                              Hazard Identification
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#hazop"
                              onClick={() => scrollToSection("hazop")}
                            >
                              Hazard & Operability
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#safop"
                              onClick={() => scrollToSection("safop")}
                            >
                              Safety & Operability / Electrical HAZOP (SAFOP/
                              eHAZOP){" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#hira"
                              onClick={() => scrollToSection("hira")}
                            >
                              Hazard Identification & Risk Assessment (HIRA)
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#envid"
                              onClick={() => scrollToSection("envid")}
                            >
                              Environmental Impact Identification (ENVID){" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#hra"
                              onClick={() => scrollToSection("hra")}
                            >
                              Health Risk Assessment (HRA){" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#phser"
                              onClick={() => scrollToSection("phser")}
                            >
                              Project HSE Review (PHSER){" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#simops"
                              onClick={() => scrollToSection("simops")}
                            >
                              Simultaneous Operations (SIMOPS){" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#mopo"
                              onClick={() => scrollToSection("mopo")}
                            >
                              Manual of Permitted Operations (MOPO)
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#bowtie"
                              onClick={() => scrollToSection("bowtie")}
                            >
                              BOW-TIE Analysis{" "}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#ohid"
                              onClick={() => scrollToSection("ohid")}
                            >
                              Occupational Health Identification (OHID)
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/hazard-analysis#ohra"
                              onClick={() => scrollToSection("ohra")}
                            >
                              Occupational Health Risk Assessment (OHRA)
                            </Link>
                          </li>
                        </ul>
                      </li>

                      {/* Risk Assessment */}
                      <li>
                        <Link to="/process-safety/risk-assessment">
                          Risk Assessment <i className="fa fa-arrow-right"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to="/process-safety/risk-assessment#QRA"
                              onClick={() => scrollToSection("QRA")}
                            >
                              Quantitative / Qualitative Risk Assessment
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/risk-assessment#GSD"
                              onClick={() => scrollToSection("GSD")}
                            >
                              Gas and Smoke Dispersion
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/risk-assessment#FERA"
                              onClick={() => scrollToSection("FERA")}
                            >
                              Fire, Explosion & Risk Assessment
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/risk-assessment#FRD"
                              onClick={() => scrollToSection("FRD")}
                            >
                              Flare Radiation and Dispersion
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/risk-assessment#EERA"
                              onClick={() => scrollToSection("EERA")}
                            >
                              Escape, Evacuation & Rescue Analysis
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/risk-assessment#ALARP"
                              onClick={() => scrollToSection("ALARP")}
                            >
                              As Low As Reasonably Practicable Demonstration
                            </Link>
                          </li>
                        </ul>
                      </li>

                      {/* Loss Prevention Activities */}

                      <li>
                        <Link to="/process-safety/loss-prevention-activities">
                          Loss Prevention Activities{" "}
                          <i className="fa fa-arrow-right"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to="/process-safety/loss-prevention-activities#HAC"
                              onClick={() => scrollToSection("HAC")}
                            >
                              Hazardous Area Classification
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/loss-prevention-activities#FNG"
                              onClick={() => scrollToSection("FGM")}
                            >
                              Fire & Gas Mapping
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/loss-prevention-activities#NNV"
                              onClick={() => scrollToSection("NVA")}
                            >
                              Noise and Vibration
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/loss-prevention-activities#FWE"
                              onClick={() => scrollToSection("FWE")}
                            >
                              Fire Water Estimation
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/loss-prevention-activities#EERA"
                              onClick={() => scrollToSection("APFPA")}
                            >
                              Active & Passive Fire Protection Analysis
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/loss-prevention-activities#FSL"
                              onClick={() => scrollToSection("FSL")}
                            >
                              Fire & Safety Layouts
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/process-safety/loss-prevention-activities#specifications"
                              onClick={() => scrollToSection("specifications")}
                            >
                              Specification
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/environment">Environment</Link>
                  </li>
                  <li>
                    <Link to="/PSM-training">
                      PSM Training And Implementation
                    </Link>
                  </li>
                  <li>
                    <Link to="/functional-safety">
                      Functional Safety <i className="fa fa-arrow-right"></i>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to="/functional-safety#sil"
                          onClick={() => scrollToSection("sil")}
                        >
                          Safety Integrity Level (SIL)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/functional-safety#FMEA-&-FMECA"
                          onClick={() => scrollToSection("FMEA-&-FMECA")}
                        >
                          Failure Modes & Effects Analysis (FMEA) & Failure
                          Modes, Effect & Criticality Analysis (FMECA)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/functional-safety#RAM"
                          onClick={() => scrollToSection("RAM")}
                        >
                          Reliability Availability Maintainability (RAM)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/functional-safety#RCM"
                          onClick={() => scrollToSection("RCM")}
                        >
                          Reliability Centered Maintenance (RCM)
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/functional-safety#MAH-&-SCE"
                          onClick={() => scrollToSection("MAH-&-SCE")}
                        >
                          Major Accident Hazard (MAH) & Safety Critical Element
                          (SCE)
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/HSE-audit">
                      HSE Audit <i className="fa fa-arrow-right"></i>{" "}
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to="/HSE-audit#process-safety-audit"
                          onClick={() =>
                            scrollToSection("process-safety-audit")
                          }
                        >
                          Process Safety
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/HSE-audit#fire-&-safety-audit"
                          onClick={() => scrollToSection("fire-&-safety-audit")}
                        >
                          Fire & Electrical Safety
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/HSE-audit#environment-compliance-audit"
                          onClick={() =>
                            scrollToSection("environment-compliance-audit")
                          }
                        >
                          Environmental Compliance
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/HSE-audit#occupational-health-&-safety-audit"
                          onClick={() =>
                            scrollToSection(
                              "occupational-health-&-safety-audit"
                            )
                          }
                        >
                          Occupational Health & Safety
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/visual-management-consultancy">Visual Management Consultancy</Link>
                  </li>
                  <li>
                    <Link to="/HSE-training">HSE Training</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/#process-lifecycle"
                  onClick={() => scrollToSection("process-lifecycle")}
                >
                  Process
                </Link>
              </li>
              <li>
                <Link to="/#clients" onClick={() => scrollToSection("clients")}>
                  Clients
                </Link>
              </li>
              <li>
                <Link
                  to="/#partners"
                  onClick={() => scrollToSection("partners")}
                >
                  Software Support
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  // onClick={() => scrollToSection("services")}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-inline-block d-lg-none">
            <div className="mobile-nav-wrap">
              <div id="hamburger" onClick={handleMobileMenu}>
                <i className="fal fa-bars"></i>
              </div>
              <MobileMenu
                mobileMenu={mobileMenu}
                handleMobileMenu={handleMobileMenu}
              />
            </div>
            <div className="overlay"></div>
          </div>
          <div className="right-elements d-none d-xl-flex d-flex align-items-center">
            <div className="call-action">
              <span>Call For Support</span>
              <p>
                <a href="tel:+91-951-837-9702"> +91-951 837 9702</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
