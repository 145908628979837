import AboutTwo from "../Components/About/AboutTwo";
import ServicesTwo from "../Components/Services/ServicesTwo";
import CtaOne from "../Components/Cta/CtaOne";
import BrandCarouselTwo from "../Components/Brands/BrandCarouselTwo";
import ProjectsTab from "../Components/Projects/ProjectsTab";
import Footer from "../Components/VA_Components/Footer/Footer";
import BlogTwo from "../Components/Blog/BlogTwo";
import TeamTwo from "../Components/Team/TeamTwo";
import WhyUs from "../Components/About/WhyUs";
import PortfolioTwo from "../Components/Portfolio/PortfolioTwo";
import HeroTwo from "../Components/Hero/HeroTwo";
import { lang } from "../Constants/Lang";
import { v4 as uuidv4 } from "uuid";
import ServiceCarousel from "../Components/VA_Components/Services/ServicesCarousel";
import Header from "../Components/VA_Components/Header/Header";
import Hero from "../Components/VA_Components/Hero/Hero";
import About from "../Components/VA_Components/About/About";
import ProcessLifecycle from "../Components/VA_Components/Lifecycle/ProcessLifecycle";
import FaqTwo from "../Components/Faq/FaqTwo";
import FunFact from "../Components/VA_Components/FunFact/FunFact";
import OurSkill from "../Components/VA_Components/Skills/OurSkill";
import OurPartners from "../Components/VA_Components/OurPartners/OurPartners";
import { scrollToSection } from "../utils/utils";
import { useEffect } from "react";

const slidesData = [
  {
    mainText: lang.T_COMPANY_NAME,
    subText: lang.T_COMPANY_MOTO,
    buttonText1: "Contact Us",
    buttonText2: "What we do",
    image: require("../assets/Images/VA_Images/Background/lighthouse_urbulent_waters.jpeg"),
  },
];

const aboutData = {
  experience: 15,
  readMore: false,
  image: require("../assets/Images/Background/industrialSafety1.png"),
  header: "We Provide",
  subHeader: "A Holistic approach to manage technical risk.",
  quoteText:
    "From design to decommissioning, our risk-based safety studies ensure your project stays on course for a safer and successful journey",
  detailText: `Vigilant Ally helps its clients manage a broad spectrum of risks, from preventing catastrophic incidents to transforming safety culture across an organization. We work collaboratively with our clients to provide insight around probable technical risks for the duration of the lifecycle of a project - from planning or early stage feasibility studies and concept design through to full-scale operation and eventual decommissioning. We examine and provide guidance to help our clients ensure that their facilities are planned, designed, operated, and maintained in such a way that process safety and major accident risks are at a level that's As Low As Reasonably Practicable (ALARP).\n As a HSE Consultancy & Service Provider Company, we focus on developing Safety and Environmental Management Systems, Processes, and Risk-based Plans for projects based on clients' business expectations and statutory requirements.\n With the guiding wings of Process experts & risk advisors, we shall be enabling services & programs that deliver Safety, Risk Management services, and HSE training to clients within and beyond the Oil and Gas sector.`,
};

const services = [
  // TODO: Need 3 more for Process safety
  {
    title: "PROCESS SAFETY",
    desc: "Vigilant Ally offers top-tier Process Hazard Analysis (PHA) services, led by experienced leaders with a strong focus on Health, Safety, and Environment (HSE) reviews. Their methodical approach ensures early identification and prevention of hazards, ensuring compliance and minimizing risks in industrial projects.",
    img: require("../assets/Images/Features/740x460/processSafety.png"),
    id: uuidv4(),
    icon: "construction-site",
    link: "/process-safety/hazard-analysis",
  },
  {
    title: "ENVIRONMENT",
    desc: "By providing expert guidance and comprehensive solutions, we help organizations minimize disruptions and create a safe working environment. This ensures that every individual can focus on their tasks with maximum productivity while optimizing the interests of all members of the community and stakeholders.",
    img: require("../assets/Images/Features/740x460/environment.png"),
    id: uuidv4(),
    icon: "power-plant",
    link: "/environment",
  },
  {
    title: "PSM TRAINING & IMPLEMENTATION",
    desc: "Vigilant Ally offers expert training and implementation services for Process Safety Management (PSM), specializing in industries handling hazardous chemicals. Their services include conducting Gap Audits, developing procedures, and ensuring compliance to prevent catastrophic incidents and protect employees, contractors, and visitors.",
    img: require("../assets/Images/Features/740x460/trainingImplementation.png"),
    id: uuidv4(),
    icon: "engineer",
    link: "/PSM-training",
  },
  {
    title: "FUNCTIONAL SAFETY",
    desc: "Vigilant Ally ihas expertise in functional safety, providing comprehensive guidance from product design to safety chain management. Their specialized services optimize systems and reduce risks, ensuring predictable responses to failures and enhancing overall safety.",
    img: require("../assets/Images/Features/740x460/functionalSafety.png"),
    id: uuidv4(),
    icon: "construction-tool-vehicle-with-crane-lifting-materials",
    link: "/functional-safety",
  },
  {
    title: "HSE AUDITS",
    desc: "Vigilant Ally specializes in a range of health, safety, and environmental audits, guiding clients through best practices and compliance with regulations for safer operations. Their experienced in-house team of HSE auditors globally assesses and improves process safety, ensuring efficient prevention, protection, and emergency management systems.",
    img: require("../assets/Images/Features/740x460/healthAudit.webp"),
    id: uuidv4(),
    icon: "secure-shield",
    link: "/HSE-audit",
  },
  {
    title: "HSE TRAINING",
    desc: "Vigilant Ally specializes in process safety and risk engineering through its customized HSE training programs. By conducting HAZOP & FMEA studies, identifying critical elements, and providing process control and SIL classification/verification, we empower clients with the knowledge and tools to ensure safety and prevent potential losses in their operations.",
    img: require("../assets/Images/Features/740x460/healthTraining.jpeg"),
    id: uuidv4(),
    icon: "notebook",
    link: "/HSE-training",
  },
  {
    title: "Visual Management Consultancy",
    desc: 'We are helping organizations improve their processes, communication, and overall efficiency by implementing visual management techniques and strategies. Visual management is a concept that emphasizes the use of visual aids, tools, and displays to communicate information, monitor performance, and facilitate decision-making within a workplace.',
    img: require("../assets/img/va_img/vmc.jpeg"),
    id: uuidv4(),
    icon: "goal",
    link: "/visual-management-consultancy",
  },
];

const processLifecycle = [
  {
    id: uuidv4(),
    ques: "Design Stage",
    ans: "Safety Studies during the design phase is equally vital as any other fragment of the design development. The design effort must be alerted about the facility's potential risks and hazards in terms of health, safety, and the environment. The design phase must include the capability to guarantee that the facility's operation is safe in terms of health, safety, and the environment.",
    index: "p1",
  },

  {
    id: uuidv4(),
    ques: "Installation Stage",
    ans: "Before commissioning, verification should be carried to ensure that there are no deviations from authorised design papers, construction materials, operating principles, design conditions, and suggested safety measures. One of the most important requirements during the installation stage is to guarantee that all necessary safety studies/reviews have been completed and that no actions are pending.",
    index: "p2",
  },

  {
    id: uuidv4(),
    ques: "Commissioning Stage",
    ans: "During commissioning, site safety processes that must be followed are available and ensure that those established processes are being implemented and followed. Identification of hazards and risks, as well as solutions for mitigating them, must be in place so that the task may be performed safely.",
    index: "p3",
  },

  {
    id: uuidv4(),
    ques: "Operation Stage",
    ans: "Safety studies must be conducted throughout time in order to address, avoid, or limit risks/hazards that may arise as a result of any changes, deviations, new installations, or other factors.",
    index: "p4",
  },
];

const Home = () => {
  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <>
      <Hero slides={slidesData} disableCarousel={true} />
      <About data={aboutData} />
      <ServiceCarousel services={services}></ServiceCarousel>
      <FunFact />
      <ProcessLifecycle data={processLifecycle}></ProcessLifecycle>
      <OurSkill />
      <OurPartners></OurPartners>
      {/* <CtaOne />
      <BrandCarouselTwo />
      <ProjectsTab />
      <FunFact />
      <WhyUs />
      <TeamTwo />
      <PortfolioTwo />
  <BlogTwo /> */}
    </>
  );
};

export default Home;
