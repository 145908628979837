import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/Images/Features/740x460/functionalSafety.png";
import mopoMatrix from "../../assets/Images/Misc/Mopo.png";
import firePieChart from "../../assets/Images/VA_Images/assets/img/fires-pie-chart.png";
import { scrollToSection } from "../../utils/utils";

const FunctionalSafety = () => {


  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={() => scrollToSection("functional-safety")}>
                      Functional Safety
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("sil")}>
                      Safety Integrity Level (SIL)
                    </Link>
                  </li>

                  <li>
                    <Link onClick={() => scrollToSection("FMEA-&-FMECA")}>
                      Failure Modes & Effects Analysis (FMEA) & Failure Modes,
                      Effect & Criticality Analysis (FMECA)
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("RAM")}>
                      Reliability Availability Maintainability
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("RCM")}>
                      Reliability Centered Maintenance
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("MAH-&-SCE")}>
                      Major Accident Hazard (MAH) & Safety Critical Element (SCE)
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-2  order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={image} alt="" />
              <h2 id="functional-safety">Functional Safety</h2>
              <p>
                Functional Safety is a segment of comprehensive safety of
                systems or pieces of equipment that depend on automatic
                protection. This automatic protection system needs to respond
                correctly to its input. And it should have a predictable
                response to failure which involve human errors, hardware
                failures, and operational/ environmental stress.
              </p>

              <p>
                Vigilant Ally has the expertise in functional safety to guide
                from product design to safety chain management, and further in
                performance and safety assessments, we consistently try to
                reduce risk and optimize the systems of clients by all means.
              </p>
              <div className="section-divider"></div>
              <h2 id="sil">Safety Integrity Level (SIL) </h2>
              <p>
                In any process sector, Safety Instrumented System (SIS) is one
                of the most critical levels of protection against accidents and
                risks. Safety Integrity Levels should be used to determine SIS
                performance standards (SIL). A SIL is a metric for assessing the
                effectiveness of a safety system. SIL 1 to 4 are the four levels
                of safety, with SIL 4 being the highest. The level of Safety
                Integrity is inversely proportional to the likelihood of failure
                of SIL. The allocation of a SIL rating is based on a SIL
                Classification Study in line with IEC 61508/ 61511.
              </p>
              <p>
                A combination of technical documentation (Piping and
                Instrumentation Diagrams (P&ID), Cause and Effect charts),
                safety studies/documentation review (HAZOP, QRA, Firefighting
                and detection system data), and economic parameters (equipment
                cost, product, and raw material costs) is required to conduct a
                SIL analysis. As a result, an initial risk level in terms of
                people's safety, environmental loss, and commercial impact will
                be identified.
              </p>

              <h6>SIL Classification Study </h6>
              <p>
                The desired Safety Integrity Level for the SIS will decide the
                configuration of the SIS to satisfy the required integrity
                level, which will increase the system's adequacy. The safety
                integrity level is determined by the risk associated with a
                certain hazard. Most frequently used methods for SIL
                Classification are:
              </p>
              <ol>
                <li>
                  <b>Risk Graph Method:</b> <br />
                  <p>
                    It is a method for determining the safety integrity level
                    (SIL) that is both qualitative and category-based. To make a
                    SIL selection, risk graph analysis employs four parameters:
                    consequence, occupancy, probability of avoiding the hazard,
                    and demand rate. Each of these criteria is allocated a
                    category, and each combination of categories is allocated a
                    SIL. This method uses a simplified analysis based on
                    knowledge of the process and the risk considerations of the
                    control system to determine the SIL level. In the absence of
                    a SIF, Risk Graph provides a calibrated assessment of a
                    hazardous situation based on a set of metrics that represent
                    those risk variables.
                  </p>
                </li>

                <li>
                  <b>Layers of Protection Analysis:</b> <br />
                  <p>
                    Layers of Protection Analysis (LOPA) is a semi-quantitative
                    risk analysis approach. The concept behind Layer of
                    Protection Analysis is to identify and analyze the
                    protective or risk-reduction layers that exist between the
                    Hazardous Event and the Initiating Causes, in order to
                    prevent the hazardous event from happening. Each of the
                    initiating events is assigned an appropriate frequency, and
                    each of the risk reduction layers – except the safety
                    Instrumented Function (SIF) – is allocated a likelihood of
                    being in a failed condition while doing a Layer of
                    Protection Analysis. Without the impact of SIF, the
                    frequency of the hazardous event is approximated.
                  </p>
                </li>
              </ol>

              <p>
                SIL ratings allow organizations to meet a system's safety level
                without needing to comprehend the system's technical details. To
                achieve SIL criteria, a device or system must meet the
                requirements for both hardware and systematic safety integrity.
              </p>

              <div className="section-divider"></div>
              {/* Fire & Electrical Safety Audit */}
              <h2 id="FMEA-&-FMECA">
                Failure Modes & Effects Analysis (FMEA) & Failure Modes, Effect
                & Criticality Analysis (FMECA) Study
              </h2>
              <p>
                <b>FMEA (Failure Modes and Effects Analysis)</b> is a systematic
                approach for examining a process to determine where and how it
                could fail, as well as to analyse the effect of various failures
                in order to identify the necessary modifications. The aim of an
                FMEA is to identify probable faults based on their severity,
                probability of occurrence, and detection likelihood. FMEA review
                includes identification of Failure Modes & evaluation of Failure
                Causes, Failure Effects & Controls available for each failure
                mode. The three numbers are multiplied together once a value is
                assigned, to produce a Risk Priority Number (RPN). Determine
                whether or not further controls are necessary based on Risk
                Priority Number (RPN).
              </p>

              <p>
                <b>Failure Modes, Effect & Criticality Analysis (FMECA)</b>{" "}
                involves two different analyses i.e.FMEA and the Criticality
                Analysis (CA). The FMEA analyzes different failure modes and
                their effects on the system whereas, CA classifies or
                prioritizes the level of importance based on failure rate and
                severity of the effect of failure. The ranking process of CA can
                be achieved by using existing failure data or by subjective
                ranking methodology conducted by a team of people with an
                understanding of the system. The FEMCA should be performed as
                soon as the preliminary design is available.FMECA is an approach
                to assess the causes of failures and their effect on production,
                safety, cost, quality, etc.
              </p>

              <div className="checked-features-list style-2">
                <p>
                  The objective of the FMEA &FMECA study is to:
                  <ul>
                    <li>
                      To determine the potential failure modes for as products
                      or process
                    </li>
                    <li>
                      Evaluate the risk associated with the failure modes{" "}
                    </li>
                    <li>Rank the issues on the basis of importance </li>
                    <li>
                      Find and carry out corrective actions to address the most
                      serious concerns{" "}
                    </li>
                  </ul>
                </p>
              </div>

              <div className="section-divider"></div>
              {/* Environmental Compliance Audit */}
              <h2 id="RAM">
                Reliability Availability Maintainability (RAM) Study
              </h2>
              <p>
                A reliability, Availability & Maintainability Study is a
                decision-making tool that highlights all possible causes of
                production losses and suggests the best system alternatives in
                order to enhance system availability and hence boost overall
                profit as well as reduce product lifecycle expenses.
              </p>

              <ol>
                <li>
                  <p>
                    <b>Concept of Reliability:</b> Design reliability or
                    operational reliability are terms used to describe the
                    capacity to execute a specified function. It refers to the
                    fraction or percentage of time that an element is capable to
                    respond to a demand. To measure reliability, many
                    methodologies such as Reliability Block Diagrams (RBDs) and
                    Fault Tree Analysis (FTA) can be utilized.
                  </p>
                </li>

                <li>
                  <p>
                    <b>Concept of Availability:</b> Availability is defined as
                    the fraction or percentage of time that an element has not
                    failed and hence is available to meet demand. In other
                    words, availability is the ability of an element to maintain
                    a functional condition within a particular environment.
                  </p>
                </li>

                <li>
                  <p>
                    <b>Concept of Maintenance:</b>
                    In order to assure equipment dependability and hence
                    reliability and availability of Safety Instrumented
                    Functions, active and reactive maintenance programs are
                    necessary. The ability to be maintained quickly and
                    efficiently (including service, inspection, check, repair,
                    and/or modification) is essential for the element's
                    lifecycle.
                  </p>
                </li>
              </ol>
              <div className="section-divider"></div>
              {/* Environmental Compliance Audit */}
              <h2 id="RCM">Reliability-Centered Maintenance (RCM) Study</h2>
              <p>
                RCM is the approach that itself provides the process that is
                used to develop proactive maintenance for an asset. During the
                application of RCM, most of the organizations focus only on the
                development of a proactive maintenance program but neglect the
                factors which have a direct effect on equipment performance, the
                schedule maintenance, emergency procedure, operational tempo and
                environment, supply issue, equipment design, training programs,
                technical publications, operating procedure.
              </p>

              <p>
                An incomplete operating process or poor equipment design can
                negatively affect equipment performance, for that reason, it is
                incredibly important that these issues are identified and
                included in RCM analysis. Including allowing the matter to be
                analyzed using RCM principles so that technically appropriate
                and effective solutions can be formulated.
              </p>

              <div className="section-divider"></div>
              {/* Environmental Compliance Audit */}
              <h2 id="MAH-&-SCE">
                Major Accident Hazard (MAH) & Safety Critical Element (SCE)
                Study
              </h2>

              <h6>Major Accident Hazards</h6>
              <p>
                A Major Accident Hazard is a source of hazard that has the
                potential to result in a major event, including several
                fatalities and/or considerable damage to plants, equipment, or
                the environment. Major Accident Hazard Management is critical
                for safe operations. Hazard identification workshops, such as
                HAZID, ENVID, and Hazards & Operability Study (HAZOP), are used
                to identify major accident hazards. Process risks are dealt with
                precisely in HAZOP. HAZOP aids in the identification of
                process-specific scenarios to be evaluated as part of FERA and
                QRA investigations, as well as the identification of possible
                barriers that might avoid, reduce, or otherwise manage the
                hazard.
              </p>
              <p>
                The COMAH (Control of Major Accidents Hazards) technique is a
                systematic procedure for identifying, analyzing, and evaluating
                Major Accident Hazards (MAH). It's a demonstration of the HSE
                Management System currently in place at a specific facility or
                activity, demonstrating that risks have been or will be reduced
                to "acceptable" or "as low as reasonably practical" (ALARP)
              </p>

              <div className="checked-features-list style-2">
                <p>
                  Five goals must be fulfilled during the analysis and
                  assessment of Major Accident Hazards:
                  <ul>
                    <li>
                      Identification of installations or areas of facilities
                      that are especially related to Major Accidents;
                    </li>
                    <li>
                      Identification of hazard sources, i.e., situations and
                      occurrences that endanger the establishment, installation,
                      or plant's safe operation during all stages of operation
                      throughout the facility's life cycle.
                    </li>
                    <li>
                      Assessment of the probability and possible consequences of
                      a major accident
                    </li>
                    <li>
                      Evaluation of the effectiveness of preventative, control,
                      and mitigation strategies; and
                    </li>
                    <li>
                      Demonstrate that risk to personnel and facilities is
                      either acceptable, tolerable or non-tolerable.
                    </li>
                  </ul>
                </p>
              </div>

              <h6>Safety Critical Elements</h6>
              <p>
                Barriers must be placed in place to control Major Accident
                Hazards. These barriers serve to prevent or lessen the impact of
                a Major Accident if one does occur. SCEs assist people working
                in the sector in identifying obstacles and understanding their
                role in ensuring that these barriers are suitable for purpose.
                They must act independently to prevent the incident from
                happening or worsening. A method for the SCEs identification to
                ensure that Major Accident Event (MAE) risks are managed to
                ALARP is based on the FARSI format where: <br />
              </p>
              {/* TODO: Style's need to be added */}
              <div>
                <p>
                  <b>F</b>UNCTIONALITY: SCE must be able to execute its
                  objective in order to prevent, detect, or mitigate a hazardous
                  occurrence.
                </p>

                <p>
                  <b>A</b>VAILABILITY: The percentage of time that the SCE will
                  be available to execute on demand.
                </p>
                <p>
                  <b>R</b>ELIABILITY: The Safety Critical Element's reliability
                  depends on how probable SCE will execute on demand.
                </p>

                <p>
                  <b>S</b>URVIVABILITY: Safety Critical Element performance
                  after a significant event, such as fire, explosion, or fallen
                  object.
                </p>
                <p>
                  <b>I</b>NTERACTION: Dependability on other SCEs to operate or
                  otherwise interacts with other SCEs.
                </p>
                <br />
                <div className="section-divider"></div>
              </div>
              
            </div>

            {/* <div className="faq-content pl-0 pl-md-4">
              <h2>Common Question for this project</h2>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunctionalSafety;
