import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import pha from "../../assets/Images/Features/740x460/processSafety.png";
import mopoMatrix from "../../assets/Images/Misc/Mopo.png";
import image3 from "../../assets/img/gallery/6.jpg";

const HazardAnalysis = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-1 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={() => scrollToSection("pha")}>
                      Process Hazard Analysis
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("hazid")}>
                      01. Hazard Identification (HAZID) Study
                    </Link>
                  </li>

                  <li>
                    <Link onClick={() => scrollToSection("hazop")}>
                      02. Hazard & Operability (HAZOP) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("safop")}>
                      03. Safety & Operability / Electrical HAZOP (SAFOP/
                      eHAZOP) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("hira")}>
                      04. Hazard Identification & Risk Assessment (HIRA) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("envid")}>
                      05. Environmental Impact Identification (ENVID) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("hra")}>
                      06. Health Risk Assessment (HRA) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("phser")}>
                      07. Project HSE Review (PHSER) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("simops")}>
                      08. Simultaneous Operations (SIMOPS) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("mopo")}>
                      09. Manual of Permitted Operations (MOPO) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("bowtie")}>
                      10. BOW-TIE Analysis Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("ohid")}>
                      11. Occupational Health Identification (OHID) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("ohra")}>
                      12. Occupational Health Risk Assessment (OHRA)
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={() => scrollToSection("conclusion")}>
                      Conclusion
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={pha} alt="" />
              <h2 id="pha">Process Hazard Analysis</h2>
              <p>
                Utmost government licensing authorities need in operation
                enterprises to carry out Health & Safety and Environmental (HSE)
                studies throughout the look of facilities, additionally as
                before construction and through operation. Hazard studies
                carried out late in the design phase identify safety and
                environmental issues that can design detainments or expensive
                design changes. Thus, a Methodical hazard study during early
                design can grease the avoidance or reduction of hazards.
              </p>
              <p>
                PHA (Process Hazard Analysis) is a structured brainstorming
                approach, to identify, analyse & prevent hazards arising from
                Unit Process & Unit Operations, which may affect Health, Safety
                & Environment along with the reputation of the facility and
                financial loss. It generally involves personnel from engineering
                disciplines, project management, commissioning, and operations.
                Major findings and hazard conditions help to deliver HSE
                compliance and form part of the project Risk Register required
                by numerous licensing authorities.
              </p>
              <p>
                Vigilant Ally provides high-quality PHA reviews based on very
                effective leaders to head up the PHA team. Our chairmen are
                highly experienced and completely focused on HSE review, having
                both environmental and process backgrounds. This ensures a broad
                and extensive knowledge of the given industrial context,
                enabling us to guide the team efficiently.
              </p>
              <div className="section-divider"></div>
              <h2 id="hazid">Hazard Identification (HAZID) Study</h2>
              <p>
                HAZID study is the workshop based qualitative risk analysis
                activity used to uncover and identify potential hazards and
                threats in a process by running through checklists and are often
                conducted at the project stages viz. Conceptual/ Basic
                Engineering, Detailed Engineering, EPC stage, Commissioning
                Stage & De-commissioning stage.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The objective of the study is to:
                  <ul>
                    <li>
                      Analysis of the process at an early stage with a view of
                      ensuring that the process design accounts for credible
                      hazardous scenarios.
                    </li>
                    <li>
                      Examine the safeguards involved in the design of the
                      process which are designed to alleviate the relevant risk
                      for the identified hazardous scenarios.
                    </li>
                    <li>
                      To ensure the quality and effectiveness of the
                      study/workshop, it is important to call for a
                      multidisciplinary team familiar with the process and its
                      operations.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="hazop">Hazard & Operability (HAZOP) Study</h2>
              <p>
                HAZOP study is the highly structured and systematic qualitative
                risk assessment approach to identify, evaluate and control
                hazard and risk in complex processes. It includes highly
                hazardous chemicals& operability concerns that can cause
                significant injuries to workers, potential environmental
                effects, extensive damage to property and company reputation if
                not properly processed and handled.
              </p>
              <div className="va-content-list style-2">
                <p>
                  It helps the firm address:
                  <ul>
                    <li>Potential hazards in business operations.</li>
                    <li>
                      Probable causes/hazards arising due to deviations from
                      normal operating conditions.
                    </li>
                    <li>
                      Past incidents that had a likelihood of catastrophic
                      consequences.
                    </li>
                    <li>Human control factor.</li>
                    <li>
                      Consequences of failure of applied control measures,
                      including the range of possible health and safety risks.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="safop">
                Safety & Operability / Electrical HAZOP (SAFOP/ eHAZOP) Study
              </h2>
              <p>
                SAFety & OPerability (SAFOP) Study refers to a series of studies
                that will be used throughout the assorted phases of a Project.
                Safety and Operability (SAFOP or eHazops) study intends to boost
                the safety of personnel operating & maintaining equipment by
                following a systematic approach.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The major objectives of SAFOP are:
                  <ul>
                    <li>
                      Enhancing the design's efficiency, safety, and
                      functionality to satisfy plant/facility requirements.
                    </li>
                    <li>
                      Addressing possible threats to key personnel involved
                      throughout the operation & maintenance of high-voltage
                      systems.
                    </li>
                    <li>
                      Assigning the design in advance to avoid wasting time and
                      effort by all parties involved.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="checked-features-list style-2">
                <p>
                  Typically, a SAFOP evaluation includes,
                  <ol>
                    <li>
                      <b>SAFetyANalysis (SAFAN)</b> is a method of assessing
                      potential risks based on the layout of a plant or
                      facility.
                    </li>
                    <li>
                      <b>SYstem Security and OPerability Analysis (SYSOP)</b>{" "}
                      examines how the system is designed to work, analyzes the
                      design, and evaluates supply security.
                    </li>
                    <li>
                      <b>OPerator Task ANalysis (OPTAN)</b> analyses the likely
                      tasks that local and distant operators will perform in
                      both normal and abnormal settings.
                    </li>
                  </ol>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="hira">
                Hazard Identification & Risk Assessment (HIRA) Study
              </h2>
              <p>
                HIRA study is the structured and systematic safety management
                system that consists of a number of sequential steps such as
                hazard identification, consequence & frequency assessment, risk
                estimation based on the existing controls and recommendations to
                reduce those risks which are not under acceptable limit.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The objectives of the study are to:
                  <ul>
                    <li>
                      To Carry out a systematic, critical appraisal of all
                      potential hazards, including personnel, plant, services,
                      and operation methods.
                    </li>
                    <li>
                      To Recognize the existing safeguards available to manage
                      the risk due to hazards.
                    </li>
                    <li>
                      Suggest additional control measures to reduce risk to an
                      acceptable level.
                    </li>
                    <li>
                      Prepare a risk register to continuously monitor these
                      risks, detect any changes, and ensure the controls are
                      effective.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="envid">
                Environmental Impact Identification (ENVID) Study
              </h2>
              <p>
                ENVID study is the workshop activity which specifically looks at
                the both planned and unplanned environmental hazards/aspects,
                their impact and their durations/likelihood identification. The
                key element of the process is to evaluate proposed measures to
                prevent, control or mitigate the potential environmental hazards
                identified.
              </p>
              <p>
                The foremost advantage of an ENVID review is that it identifies
                and establishes measures that may affect the present and
                possibly successive projects and their design process. The
                result of effective ENVID review also provides an outcome that
                enables the arrival of informed decisions regarding Environment
                management and effective design costs for successful project
                execution.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The objectives of the study are to:
                  <ul>
                    <li>
                      Systematically examine activities and analyze planned and
                      unplanned environmental aspects of the facility.
                    </li>
                    <li>
                      Identify the aspects of the environment and evaluate
                      impacts associated with all activities.
                    </li>
                    <li>
                      Examine control and monitoring measures that are in place.
                    </li>
                    <li>
                      Identify additional measures required, if any, to prevent
                      or mitigate impacts to allowable environmental standards.
                    </li>
                    <li>
                      Provide input to management in its efforts to manage risks
                      related to environmental issues.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="hra">Health Risk Assessment (HRA) Study </h2>
              <p>
                HRA Study is the approach or technique to identify those things,
                situations and processes, etc. that may cause harm, and impact a
                hazard on the health of a person, group of people or a
                community. Factual, technical information is used to understand
                the potential health effects. Each and every human health risk
                assessment is unique to the situation and population being
                assessed. The steps involved in the HRA study are Issue
                identification, Hazard assessment, examine the dose – response
                relationship, exposure assessment and characterize the risk.
              </p>
              <div className="section-divider"></div>
              <h2 id="phser">Project HSE Review (PHSER) Study </h2>
              <p>
                PHSER (Project, Health, Safety & Environment Review) is
                conducted to assure that aspects related to HSE of the process/
                engineering design are Conforming to specification, standard or
                law that has been defined in Project codes.
              </p>
              <div className="va-content-list style-2">
                <p>
                  Major objectives of PHSER are:
                  <ul>
                    <li>
                      HSE studies are being carried out & closeout/ actions
                      pending related to HSE are either complied or planned and
                      tracking is maintained with regular follow-up
                    </li>
                    <li>
                      HSE related procedures have been identified & approved by
                      regulatory authority.
                    </li>
                    <li>
                      Verify that Management of Change ensures that any type of
                      change/ deviation (Temporary or Permanent) is addressed
                      and follows HSE related aspects.
                    </li>
                    <li>
                      For Safe Operation, adequate training procedures &
                      resources are taken into consideration.
                    </li>
                    <li>
                      Engineering assurance related processes are addressed.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="checked-features-list style-2">
                <p>
                  PHSER is carried out at six stages in lifetime of a project
                  <ol>
                    <li>Stage 1: Conceptual study</li>
                    <li>Stage 2: Basic Design</li>
                    <li>Stage 3: Detail Engineering & Design</li>
                    <li>Stage 4: Construction</li>
                    <li>Stage 5: Commissioning</li>
                    <li>Stage 6: Project Operate & Monitoring</li>
                  </ol>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="simops">Simultaneous Operations (SIMOPS) Study</h2>
              <p>
                SIMOPs study is evaluation of the hazards arising with respect
                to Activities/ Tasks to be executed concurrently of two or more
                functional groups. SIMOP Study highlights potential hazards that
                can be eliminated, reduced, or managed through a risk assessment
                process involving the plant owner, project management consultant
                (PMC), main contractor, subcontractors, and vendors, or any
                other organisation that is accountable for SIMOP occurrence. To
                avoid a potentially hazardous situation, a SIMOPs study must be
                completed prior to the start of the project.
              </p>
              <div className="checked-features-list style-2">
                <p>
                  Procedure followed for SIMOPs study is as follows:
                  <ol>
                    <li>Identification of the SIMOPS scenarios</li>
                    <li>Identification of the related hazards;</li>
                    <li>
                      Evaluation of the risk of simultaneous execution of the
                      activities;
                    </li>
                    <li>Identification of control measures</li>
                    <li>Evaluation of the residual risk;</li>
                    <li>
                      Identification of additional risk control measures, if
                      required;
                    </li>
                  </ol>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="mopo">Manual of Permitted Operations (MOPO) Study</h2>
              <p>
                MOPO is a matrix, or set of matrices that provides assistance to
                operating personnel to organise and coordinate activities,
                tasks, and operations. It allows operators to take action in
                instances where safety is jeopardised, such as concurrent
                operations, external factors (e.g. extreme weather, sea states,
                visibility), and inactive safeguards (e.g. Emergency Shut Down
                (ESD), fire-fighting systems). The basic MOPO Matrix shall
                identify and differentiate conditions such as Do Not Proceed
                (i.e. operation not permitted), ‘Proceed withCaution’ (i.e.
                continue but recognize that there is an increased risk and/or
                provide additional controls)& Allowed to Proceed etc.Display of
                MOPO matrix is as below
              </p>
              <div className="align-center">
                <img src={mopoMatrix} alt="" />
              </div>
              <div className="section-divider"></div>
              <h2 id="bowtie">BOW-TIE Analysis Study </h2>
              <p>
                Bow-Tie is a graphical representation of a prospective hazard
                and its consequences, as well as the associated preventive/
                mitigative barriers and the risk posed by reduced/ unavailable
                measures (escalation factor).
              </p>
              <p>
                The Bow-Tie methodology combines three effective safety
                techniques in a synergistic way (Fault Tree Analysis, Causal
                Factors Charting and Event Tree Analysis). The Bow-Tie technique
                is quite effective for initial PHAs in terms of identifying and
                addressing high probability-high consequence occurrences. It can
                be regarded as a hybrid of a high-level fault tree and a
                high-level event tree application which provides a holistic
                picture from the basic event (Hazard) to the accident scenarios
                (Consequences) at the end state.
              </p>
              <p>
                It depicts the causes of a hazardous scenario event, the
                expected results, and the precautions taken to avoid, minimise,
                or control threats. Existing safeguards (barriers) are listed
                and their effectiveness is assessed & wherever required, further
                measures are suggested. On the pre-event side (left side) of the
                bow-tie diagram, credible causes are identified. On the
                post-event side (right side) of the Bow-tie diagram, possible
                consequences and scenario outcomes are illustrated, as well as
                corresponding barrier safeguards. One of the advantages of the
                Bow-Tie method is that it presents risks in a visual format that
                is easily understood by all levels of organization.
              </p>
              <div className="section-divider"></div>
              <h2 id="ohid">
                Occupational Health Identification (OHID) Study{" "}
              </h2>
              <p>
                Occupational health identification (OHID) is aimed at
                safeguarding the health of personnel working in a company. This
                study will evaluate the current state of occupational health in
                any industry and critically evaluate the potential risks. The
                findings of the study will propose the level of investment for
                minimising the risk and provide work environment conducive to
                human health. The Occupational Health Identification (OHID)
                technique is a team-based brainstorming concept for identifying
                occupational health hazards that can cause illness related to
                physical hazards, chemical hazards, or ergonomic hazards.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The following are the goals of the OHID Workshop research:
                  <ul>
                    <li>
                      Identify Occupational Health Risks that could lead to
                      illness.
                    </li>
                    <li>
                      Identify the causes or factors that lead to occupational
                      health hazards, such as physical hazards, chemical
                      hazards, and ergonomic hazards.
                    </li>
                    <li>
                      Review available engineering or operational measures in
                      place to mitigate/ prevent the effects
                    </li>
                    <li>
                      To achieve the ALARP, recommend additional preventive
                      and/or mitigation risk reduction measures to eliminate or
                      reduce effect of hazard.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="ohra">Occupational Health Risk Assessment (OHRA) </h2>
              <p>
                The systematic identification of health hazards in the workplace
                and subsequent assessment of health risks is referred to as
                OHRA. This procedure considers existing control measures and, if
                necessary, finds and suggests additional preventative or control
                activities.
              </p>
              <div className="va-content-list style-2">
                <p>
                  OHRA's mission is to:
                  <ul>
                    <li>
                      Identify & develop a list of elements that could be
                      harmful to health of personnel associated with workplace
                    </li>
                    <li>
                      Evaluate (evaluate) the health risk due exposure to these
                      elements using the specifications provided.
                    </li>
                    <li>
                      Decide on the necessary control measures to eliminate or
                      minimise the health risk to a level that is ALARP. If
                      control measures fail, consider any emergency actions that
                      may be required to alleviate acute and/or chronic health
                      impacts.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              {/* <h2 id="conclusion">Conclusion</h2>
              <p>
                In conclusion, hazard analysis studies play a crucial role in
                ensuring the safety and integrity of industrial processes,
                facilities, and personnel. These studies help identify potential
                hazards, assess risks, and develop strategies to prevent or
                mitigate the consequences of incidents. Each study, such as HRA,
                PHSER, SIMOPS, Bow-Tie Analysis, OHID, SIL, PSM, QRA, and ERP,
                serves a specific purpose and contributes to a comprehensive
                safety management system. By implementing the recommendations
                from these studies, organizations can minimize risks, maintain
                compliance with regulations, and protect the well-being of their
                workforce and the environment.
              </p> */}
            </div>
            {/* <div className="faq-content pl-0 pl-md-4">
              <h2>Common Question for this project</h2>
              
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HazardAnalysis;
