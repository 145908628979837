import img from "../../../assets/Images/Background/processList.png";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";

const defaultData = [
  {
    id: uuidv4(),
    ques: "How do I find the right architect for my project?",
    ans: "We have intentionally never developed a stylistic formula for our work, which is why our projects never quite look or feel the same. Each of our spaces is the result of our ability to listen.",
    index: "faq1",
  },

  {
    id: uuidv4(),
    ques: "What services do architects provide?",
    ans: "We have intentionally never developed a stylistic formula for our work, which is why our projects never quite look or feel the same. Each of our spaces is the result of our ability to listen.",
    index: "faq2",
  },

  {
    id: uuidv4(),
    ques: "How do I find the right architect for my project?",
    ans: "We have intentionally never developed a stylistic formula for our work, which is why our projects never quite look or feel the same. Each of our spaces is the result of our ability to listen.",
    index: "faq3",
  },

  {
    id: uuidv4(),
    ques: "How are architects compensated?",
    ans: "We have intentionally never developed a stylistic formula for our work, which is why our projects never quite look or feel the same. Each of our spaces is the result of our ability to listen.",
    index: "faq4",
  },

  {
    id: uuidv4(),
    ques: "Can you work with clients long distance?",
    ans: "We have intentionally never developed a stylistic formula for our work, which is why our projects never quite look or feel the same. Each of our spaces is the result of our ability to listen.",
    index: "faq5",
  },
];

const ProcessLifecycle = ({data=defaultData}) => {
  
  const [defaultOpenIndex, setDefaultOpenIndex] = useState(0);

  return (
    <section className="faq-ask-wrapper section-padding pt-0">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-12 mt-xl-0">
            {/* <div className="call-phone-cta">
              <div className="icon">
                <i className="icon-call" />
              </div>
              <div className="content">
                <span>Call for support</span>
                <h3>(+88) 541 -362 -982</h3>
              </div>
            </div> */}
            <div className="faq-img h-100">
              <img src={img} className="custom-image-fill" alt="" />
            </div>
          </div>

          <div className="col-xl-7 col-12 mt-5 mt-xl-0">
            <div className="process-lifecycle-wrapper" id='process-lifecycle'>
              <h2>PROCESS LIFECYCLE</h2>
              <div className="faq-content">
                <div className="faq-accordion">
                  <div className="accordion" id="accordion">
                    {data.map((item, index) => (
                      <div className="accordion-item" key={item.id}>
                        <h4 className="accordion-header">
                          <button
                            className={`accordion-button ${
                              index === defaultOpenIndex ? "" : "collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${item.index}`}
                            aria-expanded={
                              index === defaultOpenIndex ? "true" : "false"
                            }
                            aria-controls={item.index}
                          >
                            {item.ques}
                          </button>
                        </h4>
                        <div
                          id={item.index}
                          className={`accordion-collapse collapse ${
                            index === defaultOpenIndex ? "show" : ""
                          }`}
                          data-bs-parent="#accordion"
                        >
                          <div className="accordion-body">{item.ans}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessLifecycle;
