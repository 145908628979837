import { v4 as uuidv4 } from "uuid";
import dnvLogo from "../../../assets/Images/Partners/dnv.svg";
import shellShepherd from "../../../assets/Images/Partners/shellShepherd.png";
import soundPlan from "../../../assets/Images/Partners/soundPlan.png";
import blockSim from "../../../assets/Images/Partners/blockSim.png";
import pha from "../../../assets/Images/Partners/pha.png";
import exsilentia from "../../../assets/Images/Partners/exsilentia.svg";
import pipenet from "../../../assets/Images/Partners/pipenet.jpeg"
import aft from "../../../assets/Images/Partners/aft.png"
import flaresim from "../../../assets/img/va_img/flaresim.jpeg"
import detect_3d from "../../../assets/img/va_img/detect_3d.jpeg"

import portfolio5 from "../../../assets/img/home4/p5.jpg";


const carouselData = [
  {
    id: uuidv4(),
    img: dnvLogo,
    category: "SOFTWARE / TRAINING",
    title: "DNV PHAST Risk / SAFETI",
  },

  {
    id: uuidv4(),
    img: shellShepherd,
    category: "TECHNOLOGY",
    title: "Shell Shepherd",
  },

  {
    id: uuidv4(),
    img: soundPlan,
    category: "TECHNOLOGY",
    title: "SoundPLAN",
  },

  {
    id: uuidv4(),
    img: blockSim,
    category: "ENGINEERING",
    title: "BlockSIM",
  },

  {
    id: uuidv4(),
    img: dnvLogo,
    category: "SOFTWARE",
    title: "Maros",
  },

  {
    id: uuidv4(),
    img: pha,
    category: "SOFTWARE",
    title: "PHA-Pro/ pHA works",
  },

  {
    id: uuidv4(),
    img: exsilentia,
    category: "SOFTWARE",
    title: "exSILentia",
  },

  // {
  //   id: uuidv4(),
  //   img: portfolio5,
  //   category: "Decoration",
  //   title: "Thesis",
  // },

  {
    id: uuidv4(),
    img: detect_3d,
    category: "SOFTWARE",
    title: "Detect-3D",
  },

  {
    id: uuidv4(),
    img: pipenet,
    category: "SOFTWARE",
    title: "PIPENET",
  },

  {
    id: uuidv4(),
    img: aft,
    category: "SOFTWARE",
    title: "AFT Impulse",
  },

  {
    id: uuidv4(),
    img: flaresim,
    category: "Decoration",
    title: "Flaresim/Flarenet",
  },
];

export default carouselData;
