import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../../utils/utils";

SwiperCore.use([Autoplay, Pagination]);

const TOTAL_WORDS_ON_CARD = 37;

const truncateText = (text, maxLength) => {
  const words = text.split(" ");
  if (words.length > maxLength) {
    return words.slice(0, maxLength).join(" ") + " ...";
  }
  return text;
};

const ServiceCarousel = ({ services = [] }) => {
  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container-full">
        <div className="row">
          <div className="col-xl-12">
            <div className="block-contents text-center">
              <div className="section-title" id="services">
                <h5>Services</h5>
                <span>Our Expertise</span>
                <h2>A Powerful Solutions Suite</h2>
              </div>
            </div>
          </div>
        </div>

        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          className="row"
          autoplay={{ delay: 2000 }}
          pagination={{ clickable: true }}
          loop
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.50": {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {services.map((data) => (
            <SwiperSlide key={data.id}>
              <div className="single-service-card">
                <div
                  className="service-cover-img bg-cover"
                  style={{ backgroundImage: `url(${data.img})` }}
                ></div>
                <div className="content">
                  <div className="icon-title">
                    <div className="icon">
                      <i className={`flaticon-${data.icon}`}></i>
                    </div>
                    <div className="service-title">
                      <h4>
                        <Link onClick={scrollToTop} to={data.link}>
                          {data.title}
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <p className="truncate-text">
                  {truncateText(data.desc, TOTAL_WORDS_ON_CARD)}
                </p>
                  <Link
                    onClick={scrollToTop}
                    to={data.link}
                    className="read-more"
                  >
                    Read More <i className="fal fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default ServiceCarousel;
