import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import lossPrevent from "../../assets/Images/Features/740x460/lossPrevention.webp"

const LossPrevention = () => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-1 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={() => scrollToSection("LRA")}>
                       LOSS PREVENTION ACTIVITIES
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("HAC")}>
                      01. Hazardous Area Classification (HAC) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("FGM")}>
                      02. Fire & Gas Mapping (F&G Mapping) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("NVA")}>
                      03. Noise and Vibration (N&V Analysis) Study
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("FWE")}>
                      04. Fire Water Estimation
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("APFPA")}>
                      05. Active & Passive Fire Protection Analysis
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("FSL")}>
                      06. Fire & Safety Layouts
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => scrollToSection("specifications")}>
                      07. Specification
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={() => scrollToSection("conclusion")}>
                      Conclusion
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={lossPrevent} alt="" />
              <h2 id="LRA">LOSS PREVENTION ACTIVITIES</h2>
              <p>
                By monetizing all risks, we help organizations in developing a
                better understanding of how different hazards and scenarios
                could affect the economics of a project or the organization as a
                whole, basically we guide organizations to follow Loss
                Prevention approach.
              </p>
              <div className="section-divider"></div>
              <h2 id="HAC">Hazardous Area Classification (HAC) Study</h2>
              <p>
                There is always the chance or risk of a fire or explosion when
                electrical equipment is utilized in, around, or near an
                atmosphere that contains flammable gases or vapours, flammable
                liquids, combustible dusts, ignitable fibres or flyings.
                Hazardous (or classified) locations/areas are those where there
                is a probability or risk of fire or explosion due to an
                explosive atmosphere and/or mixture. The Class/Division system
                and the Zone system are the two systems presently used to
                classify these hazardous areas based on NFPA & IEC standards
                respectively.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The objective of Hazardous Area Classification (HAC) system is
                  <ul>
                    <li>
                      To identify places in a plant where an explosive or
                      flammable environment could exist under certain
                      conditions.{" "}
                    </li>
                    <li>
                      To enable proper equipment selection for use in such
                      areas.
                    </li>
                    <li>
                      To determine the amount and duration of flammable
                      atmospheres in normal and potentially anomalous
                      situations.
                    </li>
                  </ul>
                </p>
              </div>
              <p>
                Historically, this categorization was meant to aid in the safe
                installation of electrical equipment, but it is now being used
                to regulate all potential ignition sources.
              </p>
              <div className="checked-features-list style-2">
                <p>
                  There are four basic processes in the classification of
                  hazardous areas. They are, indeed.
                  <ol>
                    <li>
                      Identify the type of hazard in the facility, such as
                      flammable gas, flammable dust, and fibres
                    </li>
                    <li>
                      Determine the Gas/ Dust Group & Temperature Class
                      associated.
                    </li>
                    <li>
                      Evaluate the release to categorize the degree of
                      classification (Class/ Division or Zone, whichever
                      applicable)
                    </li>
                    <li>
                      Determining the extent and location for categorized
                      Division/ Zones of the Hazardous area.
                    </li>
                  </ol>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="FGM">Fire & Gas Mapping (F&G Mapping) Study</h2>
              <p>
                F&G mapping research is a layout & detector count optimization
                technique that evaluates the coverage of detection systems
                against identified hazardous scenarios using computer-aided
                three-dimensional (3D) assessment along with routine traditional
                two-dimensional (2D) assessment. This study can be performed in
                two approaches like geographical coverage & scenario based. It
                gives a verifiable assessment of the detection system's capacity
                to achieve detection performance standards. The F&G mapping
                study will focus on optimizing F&G detectors based on inherent
                process risk as established by dispersion analysis and explosion
                modelling, when appropriate. It will not, however, address the
                risk associated with fire/flame.
              </p>
              <div className="va-content-list style-2">
                <p>
                  The objectives of the performance-based F&G (detector) mapping
                  study are briefed below:
                  <ul>
                    <li>
                      To determine the F&G detection system's performance-based
                      targets as a function of the underlying process risk
                      associated with loss of containment scenarios.
                    </li>
                    <li>
                      To position the flame and hydrocarbon/toxic F&G detectors
                      such that early detection is possible.
                    </li>
                    <li>
                      To compensate for the requirements of performance-based
                      targets
                    </li>
                    <li>
                      To optimize the spatial placements of flame and
                      hydrocarbon/ toxic F&G detectors
                    </li>
                  </ul>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="NVA">Noise and Vibration (N&V Analysis) Study </h2>
              <p>
                Noise and Vibration Impact Study includes identification of
                existing sound and vibration sources, prediction of future
                levels of noise and vibration, identification of administrative
                noise and vibration limits & creation of Noise and Vibration
                Control Strategies.
              </p>
              <p>
                Quantification of the acoustical environment surrounding (sound
                levels produced) of the proposed development site current Sound
                levels (locations of the most afflicted buildings if there are
                substantial highways nearby), Ground-borne vibration (local
                sources of vibration, such as a railway or heavy industrial)
                stationary sound sources measurement (such as manufacturing,
                commercial, and institutional buildings) measurements will be
                carried out. Future acoustical analysis (rising amounts of road,
                rail, and aviation traffic over time) is frequently required in
                addition to the present recorded sound and vibration data.
              </p>
              <p>
                Flare and process vent design needs careful consideration of
                hazard and risk factors such as thermal radiation, flammable
                dispersion and noise. These factors are required to be assessed
                to define & validate suitable stack height, separation from
                adjacent facilities and to define any sterile zone.
              </p>
              <p>
                After identifying the present & future level of noise &
                vibration, criteria of assessment are set based on the level of
                noise legalized by recognized authority. Additional
                recommendations for noise management solutions are provided if
                existing or future sound levels are found to exceed the
                evaluation standards.
              </p>
              <div className="section-divider"></div>
              <h2 id="FWE">Fire Water Estimation</h2>
              <p>
                The availability of a sufficient and consistent water supply is
                crucial to firefighting capabilities, both from a system and
                manual fire fighting perspectives. Based on the dependability
                and volume required of a water supply, fire water estimation is
                a method for predicting fire flow rates and pressure vital at
                the flow in different water-based fire systems.
              </p>
              <p>
                As per corporate rules / regulations, the layout is examined to
                fulfil the fire water requirement for firefighting in the
                worst-case scenario concurrently in multiple locations. To
                fulfil the maximum demand for water, the maximum velocity,
                design flow rate, and pressure are all monitored. The hydraulic
                study of the network will be done considering several
                combinations of flow requirements at various locations in order
                to present the most realistic way of water needed in an
                emergency. The findings of each fire water network system's
                transient analysis will be created, and suggestions will be
                supplied accordingly.
              </p>
              <div className="section-divider"></div>
              <h2 id="APFPA">Active & Passive Fire Protection Analysis</h2>
              <p>
                Fire detection, fire alarm, and fire suppression systems are
                examples of active fire protection systems. These systems should
                be effective in a manner that; system is activated in any event
                of fire.
              </p>
              <p>
                Adequacy of Active Fire Protection System is analyzed based on
                Operational reliability, which is assessment of system’s ability
                to execute its intended function under certain fire exposure
                conditions.{" "}
              </p>
              <p>
                The most common form of passive fire protection is
                fire-resistant flooring and walls. Other Passive fire protection
                devices, such as those used in transformer fire barriers, are
                vital in electrical substations to ensure that fires and
                explosions do not spread to adjacent transformers, causing
                collateral damage and a disruption in key infrastructure.
                Additional to fire barriers, there are other types of passive
                fire protection such as Dampers & fire-proofing materials.
              </p>
              <div className="checked-features-list style-2">
                <p>
                  The following procedures must be followed when determining the
                  passive fire protection:
                  <ol>
                    <li>
                      Hazard assessment, including inventory and potential fuel
                      measurement
                    </li>
                    <li>
                      Determine the size of the fire scenario envelopes, develop
                      fire scenarios that include release rates and frequency.
                    </li>
                    <li>
                      Determining the requirement for fireproofing based on the
                      likelihood of an event, the time to failure, and the
                      potential consequence of damage in terms of escalation and
                      threat to life
                    </li>
                  </ol>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="FSL">Fire & Safety Layouts</h2>
              <div className="checked-features-list style-2">
                <p>
                  A fire safety layouts are comprehensive document that
                  addresses all areas of fire & safety for a particular
                  structure or property. The approach will include the following
                  layouts:
                  <ol>
                    <li>
                      <b>Fire Water Equipment Layout:</b> Sprinklers, Fire
                      hydrants, and other fire water apparatus used to
                      extinguish fire are included in this layout.
                    </li>
                    <li>
                      <b>Fire – Fighting Equipment Layout:</b> This layout
                      includes items such as fire extinguishers, fire buckets,
                      and fire doors that are used to put out fires or protect
                      people from fire.
                    </li>
                    <li>
                      <b>Safety Equipment Layout:</b> This is a chart that
                      represents how workers can protect themselves from
                      injuries, fatalities, and life-threatening circumstances
                      by wearing protective clothing, helmets, and goggles,
                      among other things.
                    </li>
                    <li>
                      <b>Escape Route and Safety Sign Layout:</b> A layout that
                      allows people to evacuate the building in a safe and
                      orderly manner.
                    </li>
                    <li>
                      <b>Fire Zone Layout:</b> A building's layout that
                      highlights the places where fire detection zones, or fire
                      alarms, are located.
                    </li>
                    <li>
                      <b>F & G Detection Location Layout:</b> A layout for Fire
                      and Gas (F&G) detection is an important aspect of the
                      mitigation system in facilities that handle and store
                      flammable and toxic materials.
                    </li>
                  </ol>
                </p>
              </div>
              <div className="section-divider"></div>
              <h2 id="specifications">Specification</h2>
              <div className="checked-features-list style-2">
                <p>
                  Specification is to ensure adequacy of various safety measures
                  which is the key requirement in ensuring the safety of
                  personnel working in the organization.
                  <ol>
                    <li>
                      <b>Specification for Safety Sign:</b> To ensure that
                      employees can clearly understand where to assemble, first
                      aid requirement, which PPE to use in case of emergency
                    </li>
                    <li>
                      <b>Specification for Noise Control:</b> To ensure that
                      adequate noise control measures are available & accessible
                      to workers, contractor & visitors as per the noise level
                      within the facility
                    </li>
                    <li>
                      <b>Specification for Safety Equipment:</b> To ensure that
                      all areas such as height, confined space, electrical, Hot
                      work are equipped with all necessary safety measures such
                      as harness for working at height, tripod kit for confined
                      space, Protective clothing for hot sparks & electric
                      shocks etc.
                    </li>
                    <li>
                      <b>Specification for Fire Fighting Equipment:</b> One of
                      the most vital specifications consists of type of fire
                      extinguisher to be used for various chemicals, no. of fire
                      buckets required & other firefighting equipment.
                    </li>
                    <li>
                      <b>Specification for Passive Fire Protection:</b> It
                      consists of an installation of sprinkler system,distance
                      of Fire hydrant to ensure coverage & other passive fire
                      protection system
                    </li>
                  </ol>
                </p>
              </div>
              <div className="section-divider"></div>
              {/* <h2 id="conclusion">Conclusion</h2>
              <p>
                The comprehensive approach of loss prevention activities
                outlined here demonstrates a robust framework for enhancing
                safety and mitigating risks within organizational environments.
                By monetizing risks, organizations can better comprehend the
                economic implications of potential hazards.
              </p>
              <p>
                The implementation of Hazardous Area Classification studies
                ensures the safe utilization of equipment in volatile
                atmospheres, while Fire & Gas Mapping studies optimize detection
                systems' performance and placement. Noise and Vibration Analysis
                studies address sound and vibration impact, enabling the
                formulation of effective control strategies. Accurate Fire Water
                Estimation guarantees adequate firefighting capabilities, and
                Active & Passive Fire Protection Analysis ensures reliable fire
                prevention and containment measures. Fire & Safety Layouts, in
                conjunction with precise specifications, further establish a
                thorough safety net, encompassing equipment, signage, escape
                routes, and protective gear. This comprehensive approach not
                only prioritizes safety but also equips organizations to operate
                with resilience and preparedness in the face of potential
                challenges.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LossPrevention;
