import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/Images/Features/740x460/trainingImplementation.png";
import mopoMatrix from "../../assets/Images/Misc/Mopo.png";
import firePieChart from "../../assets/Images/VA_Images/assets/img/fires-pie-chart.png";
import { scrollToSection } from "../../utils/utils";

const PSMTraining = () => {


  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-1 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link
                      onClick={() =>
                        scrollToSection("psm-training-&-implementation")
                      }
                    >
                      (PSM) TRAINING & IMPLEMENTATION
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={image} alt="" />
              <h2 id="hse-audit">
                PROCESS SAFETY MANAGEMENT (PSM) TRAINING & IMPLEMENTATION
              </h2>
              <p>
                Process Safety management is a systematic analytical method to
                prevent the release of highly hazardous chemicals (as defined by
                OSHA) which has the capability to cause a catastrophic incident.
                Hazard chemical comprises toxic, reactive, explosive, highly
                flammable liquids gases, or a combination of these properties.
                Using this method/technique, the process design, process
                technology, process changes, operational and maintenance
                activities and procedures, non – routine activities, emergency
                preparedness plans and procedures, training programs, and other
                elements that affects the process are all considered in the
                evaluation.
              </p>

              <p>
                Industries handling hazardous chemicals are essential to
                developing an effective PSM program that protects employees,
                contractors, and visitors of the facility. These industries
                involve Petrochemical, pharmaceutical, paints, adhesives and
                sealants, food processing, industrial organics and inorganics,
                pulp, and paper mills.
              </p>
              <p>
                To control the various type of hazards in the workplace,
                employees need to develop the necessary expertise, experience,
                judgment, and initiatives within their workforce to
                appropriately implement and maintain an effectual process safety
                management program as envisioned in the Occupational Safety and
                Health Administration (OSHA) Standard.
              </p>

              <p>
                Process Safety Management (PSM) addresses the system for
                maintaining long-term Process Safety. Wherein, Gap Audit is
                performed as a part of PSM Implementation to examine and assess
                the existing process safety practices with respect to elements
                mentioned in U.S. Occupational Safety and Health Administration
                (OSHA) 29 CFR 1910.119/ RBPS (Risk Based Process Safety)
                followed by roadmap preparation and benchmarking the facility
                for PSM Implementation journey. Implementation of PSM involves
                analysis of existing management system for process safety,
                development & training of procedures, Implementation of
                developed procedures & Evaluation of compliance of implemented
                PSM
              </p>

              <p>
                Vigilant Ally has profound knowledge & expertise in the Training
                & Implementation of various key elements of the Process Safety
                Management (PSM) Module as below, that too industry and segment
                specific.
              </p>
              <div className="section-divider"></div>

              {/* <div className="va-content-list style-2">
                <p>
                  Vigilant Ally shall audit following elements:
                  <ul>
                    <li>Health and Safety Policy</li>
                    <li>Safety Organisation and Functions</li>
                    <li>Safety Documentation</li>
                    <li>Safety Committee</li>
                    <li>Safety Budget</li>
                    <li>Accident Reporting, Investigation and Analysis</li>
                    <li>Safety Inspection</li>
                    <li>Safety Education and Training</li>
                    <li>Safety Communication/ Motivation/ Promotion</li>
                    <li>First Aid</li>
                    <li>Occupational Health Centre</li>
                    <li>House Keeping</li>
                    <li>Noise </li>
                    <li>Ventilation </li>
                    <li>Illumination</li>
                    <li>Hazard Identification and Control</li>
                    <li>Safe Operating Procedure</li>
                    <li>Work Permit system</li>
                    <li>Waste Disposal System</li>
                    <li>Personal Protective Equipment</li>
                    <li>Fire Prevention & Protection</li>
                    <li>Emergency Preparedness</li>
                    <li>Plant Layout and Hazardous Area Classification</li>
                    <li>Static Electricity</li>
                    <li>Lifting Machines and Tackles</li>
                    <li>Tank Storage Vessel Area</li>
                    <li>Colour Coding for Pipelines</li>
                    <li>Maintenance System</li>
                    <li>Transportation of Hazardous Material/Chemical</li>
                  </ul>
                </p>
              </div> */}
            </div>

            {/* <div className="faq-content pl-0 pl-md-4">
              <h2>Common Question for this project</h2>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PSMTraining;
