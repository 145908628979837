import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import environment from "../../assets/Images/Features/740x460/environment.png";
import { scrollToSection } from "../../utils/utils";

const Environment = () => {

  useEffect(() => {
    const viewPort = window.location?.hash?.slice(1);
    if (viewPort.length) {
      scrollToSection(viewPort);
    }
  }, []);

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Categories</h3>
                </div>
                <ul>
                  <li>
                    <Link
                      onClick={() =>
                        scrollToSection("environment")
                      }
                    >
                      ENVIRONMENT
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-2 order-md-2">
            <div className="service-details-content-wrapper va-content-wrapper pl-0 pl-md-4">
              <img src={environment} alt="" />
              <h2 id="environment">ENVIRONMENT</h2>
              <p>
                We always help our clients to achieve and maintain HSE
                compliance standards. We aide organizations in reducing
                disruptions, so that every individual can focus on maximum
                productivity and optimization of the interests of every member
                of the community and stakeholder.
              </p>
              <p>
                We invariably focus to assess and quantify various types of
                hazards and risks in the vicinity, including risk related to
                explosion, monitoring and assessing environmental impact, and
                precisely estimating occupational health issues & hazards.
              </p>
              <div className="checked-features-list style-2">
                <p>
                  <ol>
                    <li>Feasibility Studies</li>
                    <li>Detailed Project Reports</li>
                    <li>Environmental Monitoring</li>
                    <li>Baseline Data Collection</li>
                    <li>Environmental Audits</li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Environment;
